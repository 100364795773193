import { BrowserModule } from '@angular/platform-browser';
import { NgModule, SystemJsNgModuleLoader, APP_INITIALIZER } from '@angular/core';
import { HttpClient, HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { initializeApp, constantsApp} from './app.initializer';
import { AppConfig } from './app.config';
import { CommonModule, HashLocationStrategy, LocationStrategy, DatePipe } from '@angular/common';
import { SharedModule } from './shared/shared.module';
import { AuthGuard } from './auth.guard';
import { CommonModalpopupComponent } from './components/common-modalpopup/common-modalpopup.component';
import { AuditorDetailsUpdateComponent } from './components/auditor-details-update/auditor-details-update.component';
import { VendorSupplierCommentsComponent } from './components/vendor-supplier-comments/vendor-supplier-comments.component';
import { SamplingParametersListComponent } from './components/sampling-parameters-list/sampling-parameters-list.component';

import { LoginGuard } from 'src/app/login.guard';
import { AlertMessageComponent } from './components/alert-message/alert-message.component';
import { UpdateClientDetailsComponent } from './components/update-client-details/update-client-details.component';
import { DeleteClientUploadFilesComponent } from './components/delete-client-upload-files/delete-client-upload-files.component';
import { AlertVendorSupplierComponent } from './components/alert-vendor-supplier/alert-vendor-supplier.component';
import { ConfirmationMessageComponent } from './components/confirmation-message/confirmation-message.component';
import { AuthInterceptor } from './app.interceptor';
import { Router } from '@angular/router';
import { DataService } from './data.service';
import { ClientEmailAddComponent } from './components/client-email-add/client-email-add.component';
import {AutocompleteLibModule} from 'angular-ng-autocomplete';
import { CalculatedInterpolationSummaryDetailsComponent } from './components/calculated-interpolation-summary-details/calculated-interpolation-summary-details.component';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
export const createTranslateLoader = (http : HttpClient) =>
{
  return new TranslateHttpLoader(http,'./assets/i18n/','.json');
}
@NgModule({
  declarations: [
    AppComponent,
    CommonModalpopupComponent,
    AuditorDetailsUpdateComponent,
    VendorSupplierCommentsComponent,
    SamplingParametersListComponent,
    AlertMessageComponent,
    UpdateClientDetailsComponent,
    DeleteClientUploadFilesComponent,
    AlertVendorSupplierComponent,
    ConfirmationMessageComponent,
    ClientEmailAddComponent,
    CalculatedInterpolationSummaryDetailsComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    SharedModule,
    HttpClientModule, AutocompleteLibModule,
    TranslateModule.forRoot({
      loader : {
        provide : TranslateLoader,
        useFactory : createTranslateLoader,
        // useClass: TranslateHttpLoader,
        deps : [HttpClient]
      }
    })
  ],
  providers:  [AuthGuard, LoginGuard, AppConfig,     {
      provide: APP_INITIALIZER,
      useFactory: initializeApp,
      deps: [AppConfig], multi: true
    },
    {
      provide: APP_INITIALIZER,
      useFactory: constantsApp,
      deps: [AppConfig], multi: true
    },
    // {
    //   provide: APP_INITIALIZER,
    //   useFactory: initializGermanApp,
    //   deps: [AppConfig], multi: true
    // },
    {
      provide: LocationStrategy,
      useClass: HashLocationStrategy
    },
      SystemJsNgModuleLoader, {
      provide: LocationStrategy,
      useClass: HashLocationStrategy
    },
  {
    
    provide: HTTP_INTERCEPTORS,
    useFactory: function(router: Router, dataService: DataService) {
      return new AuthInterceptor(router, dataService);
    },
    multi: true,
    deps: [Router]
  }],
  bootstrap: [AppComponent],
  entryComponents: [CommonModalpopupComponent, AuditorDetailsUpdateComponent, VendorSupplierCommentsComponent,CalculatedInterpolationSummaryDetailsComponent, SamplingParametersListComponent, AlertMessageComponent, UpdateClientDetailsComponent, DeleteClientUploadFilesComponent, AlertVendorSupplierComponent, ConfirmationMessageComponent, ClientEmailAddComponent]
})
export class AppModule { }
