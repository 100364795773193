import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AppConfig } from 'src/app/app.config';
import { TranslateService } from  '@ngx-translate/core';
import { NgiNotificationService } from 'ngi-notification';
import { NgiLoggerService } from 'ngi-logger';
declare var $: any;
@Component({
  selector: 'app-asidenavbar',
  templateUrl: './asidenavbar.component.html',
  styleUrls: ['./asidenavbar.component.scss']
})
export class AsidenavbarComponent implements OnInit {
  @Input() choosedLang : String;
  filemanagement = false;
  LinearInterpolationComponent = false;
  sendEmail = false;
  summaryDetails = false;
  auditorUploadFile = false;
  wpAdmin = false;
  auditorAdmin = false;
  clientAdmin = false;
  musStatusReport = false;
  archiveSupportingDocument = false;
  userType;
  getSideNavInfo: any;
  getsideUserType: any;
  submenuColor:any;
  languageCode: String;
  constructor(public translation:  TranslateService,private router: Router, private notification: NgiNotificationService, private logger: NgiLoggerService) {
    if (router.url === "/dashboard/MUS-process") {
      this.filemanagement = true;
    }
    else if(router.url === "/dashboard/Evaluation"){
       this.LinearInterpolationComponent =true;
    } 
    else if (router.url === "/dashboard/view-mail-and-document-details") {
      this.sendEmail = true;
    } else if (router.url === "/dashboard/view-summary-details") {
      this.summaryDetails = true;
    } else if (router.url === "/dashboard/auditor-MUS-Status-report") {
      this.musStatusReport = true;
    } else if (router.url === "/dashboard/archive-supporting-Documents-report" || router.url === "/dashboard/archive") {
      this.archiveSupportingDocument = true;
    } else if (router.url === "/dashboard/file-upload") {
      this.auditorUploadFile = true;
    } 
    this.submenuColor = '#0c0c0c85';
  }

  ngOnInit() {
    this.userType = AppConfig.constants.user_type;

    // valid user type best open menu
    try {
      if (this.userType === AppConfig.constants.USERTYPELIST.ONE) {
        this.wpAdmin = true;
      } else if (this.userType === AppConfig.constants.USERTYPELIST.TWO) {
        this.auditorAdmin = true;
      } else if (this.userType === AppConfig.constants.USERTYPELIST.THREE) {
        this.clientAdmin = true;
      }

    } catch (error) {
      this.logger.error(error);
    }
  }
  langugagechoosed(lang:string)
  {
    this.translation.use( lang );
  }
}
