import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'objectHeaders'
})
export class NgiObjectHeadersPipe implements PipeTransform {
    transform(value: any, numofLetters: number = 3, showColumninfo: any): any {
        const headers: any = [];
        // this pipe library only fixed temporary fixes, we need to improve this library for dynamic header designs
        if (value !== undefined) {
            showColumninfo.forEach(element => {
                if (numofLetters === undefined) {
                    headers.push(element.toUpperCase());
                } else {
                    const newstr = element.replace(/[_]/g, ' ');
                    const output = newstr.split(' ').map((s) => s.charAt(0).toUpperCase() + s.substring(1)).join(' ');
                    headers.push(output);
                }
            });
            return headers;
        } else {
            return value;
        }
    }
}

