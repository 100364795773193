import { Injectable } from '@angular/core';
import { Observable, of  } from 'rxjs';
import { DataService } from 'src/app/data.service';
import { AuthService } from '../app/services/auth.service';

import { AppConfig } from 'src/app/app.config';
import { map, catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  Router,
  UrlTree,
} from '@angular/router';
import { HttpClient } from '@angular/common/http';
@Injectable({
    providedIn: 'root'
  })
  export class LoginGuard implements CanActivate {
    // userType=AppConfig.constants.user_type;

    constructor(private router: Router, public dataService: DataService,private httpClient: HttpClient,   private authService: AuthService) { }
    canActivate(
        next: ActivatedRouteSnapshot,
        state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
          return this.httpClient.get(environment.HOST +AppConfig.settings.projects.TOKENVALIDATE,{withCredentials:true}).pipe(
            map(res => {
              if (res['status']==="false") {
                return true;
              } else {
                this.router.navigate(['dashboard'])
                return false;
              }
            }),
            catchError((err) => {
              return of(false);
            })
          );
      
        
      }
  }
