import { Component, OnInit, Input, Output, EventEmitter, Injector, AfterViewInit, Type  } from '@angular/core';
import { FormGroup, FormBuilder, FormControl, FormArray, Validators} from '@angular/forms';
import { Routes, RouterModule, Router } from '@angular/router';
import { AppConfig } from 'src/app/app.config';
import { DataService } from 'src/app/data.service';
import { NgiNotificationService } from 'ngi-notification';
import { SharedService } from 'src/app/shared.service';
import { HttpParams } from '@angular/common/http';
import { NgiLoggerService } from 'ngi-logger';
import { TranslateService } from  '@ngx-translate/core';
@Component({
  selector: 'app-sampling-parameters-list',
  templateUrl: './sampling-parameters-list.component.html',
  styleUrls: ['./sampling-parameters-list.component.scss']
})
export class SamplingParametersListComponent implements OnInit {
  @Output('CloseWidget') CloseWidget: EventEmitter<any> = new EventEmitter();
  commonLoader = AppConfig.constants.COLORCODE.commonloadcolor; // common loader color code
  loginloader: string = this.randomGenerator();  // Loader variable declare
  injectData: any;
  betaDetail;
  confidenceFactor;
  confidenceLevel;
  expectedMisstatement;
  numberOfMisstatement;
  populationSize;
  populationValue;
  sampleInterval;
  tolerableMisstatement;
  fileType;
  sampleSize;
  sampleSizeObtained;
  MUSSummaryDetails;
  confirmationDate;
  sampling_based_column;
  constructor( public translation:  TranslateService,private sharedService: SharedService, private fb: FormBuilder, private router: Router, private notification: NgiNotificationService, public dataService: DataService, private injector: Injector, private logger: NgiLoggerService) {
    this.injectData = this.injector.get('injectData');
   }

  ngOnInit() {
    this.viewSummaryList();
  }
 // Loader randomgenerator script
 randomGenerator(): string {
  return Math.floor(Math.random() * 100) + 2 + '' + new Date().getTime() + Math.floor(Math.random() * 100) + 2 + (Math.random().toString(36).replace(/[^a-zA-Z]+/g, '').substr(0, 5));
}
/* This is function use to view summary details
@header: token
@URL: service API
@input: injectdata value,
@output: {status:"", message:"", data:[]}
*/
viewSummaryList() {
  try {
  this.dataService.postData(AppConfig.settings.BALCALCULATION.MUSSUMMARY, this.injectData.data, '').subscribe(response => {
    try {
      if (response.status === 'true') { // response successfully
        this.notification.isUILoader(false, this.loginloader, this.commonLoader);
        this.MUSSummaryDetails = response.data;
        this.betaDetail = this.MUSSummaryDetails.beta;
        this.confidenceFactor = this.MUSSummaryDetails.confidence_factor;
        this.confidenceLevel = this.MUSSummaryDetails.confidence_level;
        this.expectedMisstatement = this.MUSSummaryDetails.expected_misstatement;
        this.numberOfMisstatement = this.MUSSummaryDetails.number_of_misstatement;
        this.populationSize = this.MUSSummaryDetails.population_size;
        this.populationValue = this.MUSSummaryDetails.population_value;
        this.sampleInterval = this.MUSSummaryDetails.sample_interval;
        this.sampleSize = this.MUSSummaryDetails.sample_size;
        this.tolerableMisstatement = this.MUSSummaryDetails.tolerable_misstatement;
        this.fileType = this.MUSSummaryDetails.file_type;
        this.sampleSizeObtained = this.MUSSummaryDetails.sample_size_obtained;
        this.confirmationDate = this.MUSSummaryDetails.vendor_supplier_confirmation_date;
        this.sampling_based_column = this.MUSSummaryDetails.sampling_based_column;

      } else { // response failed
        this.notification.isUILoader(false, this.loginloader, this.commonLoader);
        this.notification.isNotification(true, this.translation.instant('Failure'), this.translation.instant(response.message), 'times-circle');
      }
    } catch (error) {
      this.logger.error(error);
    }
  }); } catch (error) {
    this.logger.error(error);
  }
    }
cancelBtn() {
  this.CloseWidget.emit('CloseWidget');
}
}
