import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, of, BehaviorSubject } from 'rxjs';
import { Router } from '@angular/router';
import { AppConfig } from 'src/app/app.config';
import { DataService } from 'src/app/data.service';
import { NgiNotificationService } from 'ngi-notification';
import { environment } from 'src/environments/environment';
import { ShareLoginDetailsService } from 'src/app/_helpers/login-user-details/share-login-details.service';
import { NgiLoggerService } from 'ngi-logger';
@Injectable({
  providedIn: 'root'
})

export class AuthService {
  isAuthenticate = new BehaviorSubject<boolean>(false);
  constructor(private http: HttpClient, private router: Router, public dataService: DataService, private notification: NgiNotificationService, private shareLogin: ShareLoginDetailsService,private logger:NgiLoggerService) {}
// This is the function used to every function call check token values
  async tokenCheck(): Promise<any> {
    return new Promise((resolve, reject) => {
        this.dataService.getData(AppConfig.settings.projects.TOKENVALIDATE,'').subscribe(response => {

          try {
            if (response.status === 'false') {
              this.isAuthenticate.next(false)
              // this.router.navigate(['/login']);
            }
            else{
              this.isAuthenticate.next(true)
            }
           
            resolve(true);
          } catch (error) {
            reject(false)
            this.logger.error(error);
          }
        });
    
    });
  }
  // This is the function used to logout to application
  logout() {

    this.isAuthenticate.next(false);
  }
  // This is the function used to store to token value
  loggedIn() {
    return this.isAuthenticate;
  }



}
