import { Injectable } from '@angular/core';
import { Subject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SharedService {


  // Observable string sources
  private missionAnnouncedSource = new Subject<string>();
  private subject = new Subject<any>();
   // Observable string streams
   missionAnnounced$ = this.missionAnnouncedSource.asObservable();

  constructor() { }

  // Service message commands
  announceMission(mission: string) {
    this.missionAnnouncedSource.next(mission);
  }
  sendData(data: any) {
    this.subject.next(data);
  }

  clearData() {
    this.subject.next();
  }

  getData(): Observable<any> {
    return this.subject.asObservable();
  }


}
