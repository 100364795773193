import { Component, OnInit, Input, Output, EventEmitter, Injector, AfterViewInit } from '@angular/core';
import { FormGroup, FormBuilder, FormControl, FormArray, Validators } from '@angular/forms';
import { Routes, RouterModule, Router } from '@angular/router';
import { AppConfig } from 'src/app/app.config';
import { DataService } from 'src/app/data.service';
import { NgiNotificationService } from 'ngi-notification';
import { SharedService } from 'src/app/shared.service';

import { TranslateService } from  '@ngx-translate/core';
import * as moment from 'moment';
import { NgiLoggerService } from 'ngi-logger';
@Component({
  selector: 'app-common-modalpopup',
  templateUrl: './common-modalpopup.component.html',
  styleUrls: ['./common-modalpopup.component.scss']
})
export class CommonModalpopupComponent implements OnInit, AfterViewInit {
  @Output() addMailData: EventEmitter<any> = new EventEmitter();
  @Output('CloseWidget') CloseWidget: EventEmitter<any> = new EventEmitter();
  injectData: any;
  settings: any;
  postValueConfirm:any;
  mailTemplete: any;
  otherItemsListTemplate:any;
  commonLoader = AppConfig.constants.COLORCODE.commonloadcolor;
  loginloader: string = this.randomGenerator();  // loader variable declare
  getMailFileDetails: any;
  balanceCompleted = false;
  balanceDeviation = false;
  vendorSupplierEmail = false;
  vendorSupplierTemplateupload = false;
  musProcessDetails = false;
  showPopupSummary = false;
  randomSelect = false;
  balancecompMsg;
  balancedeviMsg;
  updateMailDetails;
  userTypeDetails;
  MUSSummaryDetails;
  expectedMisstatement;
  tolerableMisstatement;
  getTriggerValue;
  userTriggerDetail;
  musTriggerDetails;
  sampleSize;
  triggerMUSDetails;
  vsemailfile = true;
  vssuccessmsg = false;
  showMessage;
  messageColor;
  backendResponse:any;
  compUpperValid:any;
  validaMessage:any;
  upperValid: any;
  now = new Date();
  year = this.now.getFullYear();
  month = this.now.getMonth();
  day = this.now.getDay();
  minDate = moment(new Date()).format('YYYY-MM-DD');
  maxDate = '2100-12-08';
  // nameTitle: any = ['Mr.', 'Mrs.', 'Miss.', 'Ms.']; // common for both german and english
  nameTitle: any ;
  // vendor and supplier email update place
  getUpdateMailData = new FormGroup({
    vendor_or_supplier_email: new FormControl('', [Validators.required, Validators.pattern(AppConfig.settings.formvalidkay.EMAILPATTEN)]),
    salutation: new FormControl(null, [ Validators.required ] ),
    first_name: new FormControl('',  [Validators.required, Validators.minLength(AppConfig.settings.formvalidkay.FIRSTNAMEMINIMUM), Validators.maxLength(AppConfig.settings.formvalidkay.FIRSTNAMEMAXIMUM), Validators.pattern(AppConfig.settings.formvalidkay.STRINGPATTAN)]),
    last_name: new FormControl('',  [Validators.required, Validators.minLength(AppConfig.settings.formvalidkay.LASTNAMEMINIMUM), Validators.maxLength(AppConfig.settings.formvalidkay.LASTNAMEMAXIMUM), Validators.pattern(AppConfig.settings.formvalidkay.CHARACTERSALONGWITHHYPHEN)]),
    mail_template: new FormControl(''),
    other_items_list: new FormControl('')
  });
    // vendor and supplier email update place
    getUpdateTemple = new FormGroup({
      mail_template: new FormControl('', [Validators.required])
    });
  // MUS trigger function here
  triggerMUSForm = this.fb.group({
    expectedMisstatement: ['', [Validators.required, Validators.pattern(AppConfig.settings.formvalidkay.NUMBERPATTEN), Validators.minLength(1), Validators.maxLength(6), Validators.min(0), Validators.max(100)]],
    tolerableMisstatement: ['', [Validators.required, Validators.pattern(AppConfig.settings.formvalidkay.TRIGGERVALUE), Validators.minLength(1), Validators.maxLength(7), Validators.min(0), Validators.max(100)]],
    confidenceLevel: ['', [Validators.required, Validators.pattern(AppConfig.settings.formvalidkay.TRIGGERVALUE), Validators.minLength(1), Validators.maxLength(7), Validators.min(0), Validators.max(99)]],
    confirmationDate: ['', [Validators.required]],
    selectColumn: ['', [Validators.required]]
  });

  randomSelectForm = this.fb.group({
    lowerLimit: ['', [Validators.required, Validators.pattern(AppConfig.settings.formvalidkay.NUMBERPATTEN), Validators.minLength(1), Validators.maxLength(6), Validators.min(1), Validators.max(10000)]],
    upperLimit: ['', [Validators.required, Validators.pattern(AppConfig.settings.formvalidkay.NUMBERPATTEN), Validators.minLength(1), Validators.maxLength(7), Validators.min(1), Validators.max(10000)]],
    generate: ['', [Validators.required, Validators.pattern(AppConfig.settings.formvalidkay.NUMBERPATTEN), Validators.minLength(1), Validators.maxLength(7), Validators.min(1), Validators.max(10000)]]
  });
  other_items_list: any;

  constructor(public translation:  TranslateService,private sharedService: SharedService, private fb: FormBuilder, private router: Router, private notification: NgiNotificationService, public dataService: DataService, private injector: Injector, private logger: NgiLoggerService) {
    this.injectData = this.injector.get('injectData');
    this.upperValid = true;
    this.validaMessage = "Generate value should not be greater than upper limit"
    this.compUpperValid = false;

    if(localStorage.getItem('TRANSLATIONLANGINFO') === 'de')
    {
      this.nameTitle = AppConfig.constants.GERMANSALUTATION;
    }else{
      this.nameTitle = AppConfig.constants.ENGSALUTATION;
    }
  }
  ngOnInit(): void {
    try {
      if (this.injectData.action === AppConfig.constants.CONDITIONTEXT.fileCompleted) {
        // valid inject data type fileCompleted message
        this.balanceCompleted = true;
        this.balancecompMsg = AppConfig.constants.COMMOMBOX.BALANCECOMPLETED;
      } else if (this.injectData.action === AppConfig.constants.CONDITIONTEXT.fileDeviaton) {
        // valid inject data type fileDeviation message
        this.balanceDeviation = true;
        this.balancedeviMsg = AppConfig.constants.COMMOMBOX.BALANCEDEVIATION;
      } else if (this.injectData.action === AppConfig.constants.CONDITIONTEXT.addEmail) {
        // valid inject data type template Add to vendor and supplier email id 
        this.vendorSupplierEmail = true;
        this.updateClientEmail();
      } else if (this.injectData.action === AppConfig.constants.CONDITIONTEXT.addTemplateAlone){

        this.vendorSupplierTemplateupload = true;
      } else if (this.injectData.action === AppConfig.constants.CONDITIONTEXT.musProcessDetails) {
        // valid inject data type MUS process details
        this.musProcessDetails = true;
        this.userTypeDetails = this.injectData.data.file_type;
      } else if (this.injectData.action === AppConfig.constants.CONDITIONTEXT.randomSelectView) {
        // valid inject data type MUS process details
        this.getUpdateExcelValue();
        this.randomSelect = false;
        this.userTypeDetails = this.injectData.data.file_type;
      }
    } catch (error) {
      this.logger.error(error);
    }
  }

  updateClientEmail() {
    let emailId:any = this.injectData.data.getData.vendor_supplier_email;
    let salutation:any = this.injectData.data.getData.salutation;
    let first_name:any = this.injectData.data.getData.first_name;
    let last_name:any = this.injectData.data.getData.last_name;
    if(emailId === '--' || emailId === 'false' ){
    }else {
      this.getUpdateMailData.patchValue({
        vendor_or_supplier_email: emailId,
        salutation:salutation,
        first_name:first_name,
        last_name:last_name
      });
    }
  }
  // Loader randomgenerator script
  randomGenerator(): string {
    return Math.floor(Math.random() * 100) + 2 + '' + new Date().getTime() + Math.floor(Math.random() * 100) + 2 + (Math.random().toString(36).replace(/[^a-zA-Z]+/g, '').substr(0, 5));
  }
  ngAfterViewInit() {
    // this.notification.isUILoader(true, this.loginloader, this.commonLoader);
  }
  onFileChange(event) {
    const fileList: FileList = event.target.files;
    this.mailTemplete = fileList[0];
  }
  onOtherItemsTemplateFileChange(event) {
    const otherFileList: FileList = event.target.files;
    this.otherItemsListTemplate = otherFileList[0];
  }
  cancelBtn() {
    this.CloseWidget.emit('CloseWidget');
  }

  onSubmitTemplateForm()
  {
   
    const formData: FormData = new FormData();
    if (this.otherItemsListTemplate === undefined){
      formData.append('client_email', this.injectData.data.getData.client_email);
      formData.append('file_type', this.injectData.data.getData.file_type);
      formData.append('mail_template', this.mailTemplete, this.mailTemplete.name);
      formData.append('vendor_or_supplier_ids_and_mail', JSON.stringify( this.injectData.data.getData.vendor_or_supplier_ids_and_mail ));
          
    }
    else{
    formData.append('client_email', this.injectData.data.getData.client_email);
    formData.append('file_type', this.injectData.data.getData.file_type);
    formData.append('mail_template', this.mailTemplete, this.mailTemplete.name);
    formData.append('other_items_list', this.otherItemsListTemplate, this.otherItemsListTemplate.name);
    formData.append('vendor_or_supplier_ids_and_mail', JSON.stringify( this.injectData.data.getData.vendor_or_supplier_ids_and_mail ));
    }
    this.notification.isUILoader(true, this.loginloader, this.commonLoader);
    this.dataService.postData(AppConfig.settings.BALCALCULATION.ONECLICKUPLOADTEMPLATESTOACCOUNTS, formData, '').subscribe(response => {
    try
    {
      if (response.status === 'true') {
        this.notification.isUILoader(false, this.loginloader, this.commonLoader);
        this.vsemailfile = false;
        this.vssuccessmsg = true;
        this.notification.isUILoader(false, this.loginloader, this.commonLoader);
        this.messageColor = AppConfig.constants.COLORCODE.successColor;
        this.showMessage = response.message;
        setTimeout(() => {
          this.vsemailfile = true;
          this.vssuccessmsg = false;
          this.sharedService.announceMission(response.data[0].user_type);
          this.cancelBtn();
        }, 3000);
      } else {
        this.notification.isUILoader(false, this.loginloader, this.commonLoader);
        this.vsemailfile = false;
        this.vssuccessmsg = true;
        this.notification.isUILoader(false, this.loginloader, this.commonLoader);
        this.messageColor = AppConfig.constants.COLORCODE.deviationColor;
        this.showMessage = response.message;
        setTimeout(() => {
          this.vsemailfile = true;
          this.vssuccessmsg = false;
          this.getUpdateMailData.reset();
        }, 3000);
        this.notification.isNotification(true, this.translation.instant('Failure'), this.translation.instant(response.message), 'times-circle');
      }
    }
    catch(error)
    {
      this.logger.error(error);
    }
    });
    
  }
  /* This is function use to Submit email id and document vendor or supplier details
  @header: token
  @URL: service API
  @input: file_type:"", sampling_selected_id:"", client_email:"", vendor_or_supplier_email:"", mail_template
  @output: {status:"", message:"", data:[]}
  */
  onSubmitEmailForm() {
    this.getMailFileDetails = this.getUpdateMailData.value;
    try {
        this.vsemailfile = true;
        this.vssuccessmsg = false;
        this.notification.isUILoader(true, this.loginloader, this.commonLoader);
        this.getMailFileDetails = this.getUpdateMailData.value;
        const formData: FormData = new FormData();
        if (this.mailTemplete === undefined && this.otherItemsListTemplate === undefined){
          formData.append('sampling_selected_id', this.injectData.data.sampling_selected_id);
          formData.append('client_email', this.injectData.data.client_email);
          formData.append('vendor_or_supplier_email', this.getMailFileDetails.vendor_or_supplier_email);
          formData.append('salutation', this.getMailFileDetails.salutation);
          formData.append('first_name', this.getMailFileDetails.first_name);
          formData.append('last_name', this.getMailFileDetails.last_name);
          formData.append('file_type', this.injectData.data.file_type);  
        }else if(this.otherItemsListTemplate === undefined){
          formData.append('sampling_selected_id', this.injectData.data.sampling_selected_id);
          formData.append('client_email', this.injectData.data.client_email);
          formData.append('vendor_or_supplier_email', this.getMailFileDetails.vendor_or_supplier_email);
          formData.append('salutation', this.getMailFileDetails.salutation);
          formData.append('first_name', this.getMailFileDetails.first_name);
          formData.append('last_name', this.getMailFileDetails.last_name);
          formData.append('file_type', this.injectData.data.file_type);
          formData.append('mail_template', this.mailTemplete, this.mailTemplete.name);
        }
         else{
          formData.append('sampling_selected_id', this.injectData.data.sampling_selected_id);
          formData.append('client_email', this.injectData.data.client_email);
          formData.append('vendor_or_supplier_email', this.getMailFileDetails.vendor_or_supplier_email);
          formData.append('salutation', this.getMailFileDetails.salutation);
          formData.append('first_name', this.getMailFileDetails.first_name);
          formData.append('last_name', this.getMailFileDetails.last_name);
          formData.append('file_type', this.injectData.data.file_type);
          formData.append('mail_template', this.mailTemplete, this.mailTemplete.name);
          formData.append('other_items_list',this.otherItemsListTemplate,this.otherItemsListTemplate.name);
        }
        
        this.dataService.postData(AppConfig.settings.BALCALCULATION.SENDMAILCLIENT, formData, '').subscribe(response => {
          try {
            if (response.status === 'true') {
              this.vsemailfile = false;
              this.vssuccessmsg = true;
              this.notification.isUILoader(false, this.loginloader, this.commonLoader);
              this.messageColor = AppConfig.constants.COLORCODE.successColor;
              this.showMessage = response.message;
              setTimeout(() => {
                this.vsemailfile = true;
                this.vssuccessmsg = false;
                this.sharedService.announceMission(this.injectData.data.file_type);
                this.cancelBtn();
              }, 3000);
            } else {
              this.vsemailfile = false;
              this.vssuccessmsg = true;
              this.notification.isUILoader(false, this.loginloader, this.commonLoader);
              this.messageColor = AppConfig.constants.COLORCODE.deviationColor;
              this.showMessage = response.message;
              setTimeout(() => {
                this.vsemailfile = true;
                this.vssuccessmsg = false;
                this.getUpdateMailData.reset();
              }, 3000);
              this.notification.isNotification(true, this.translation.instant('Failure'), this.translation.instant(response.message), 'times-circle');
            }
          } catch (error) {
            this.logger.error(error);
          }
        });
    } catch (error) {
      this.logger.error(error);
    }
  }
  lowerLimitCheck = (getValue: any) => {
    let randomSelectionValue: any = this.randomSelectForm.value;
    if(getValue.target.value && parseInt(randomSelectionValue.upperLimit)){
      let postValue: any =  {
        lower_limit:parseInt(getValue.target.value),
        upper_limit :parseInt(randomSelectionValue.upperLimit),
        sampling_id:parseInt(this.injectData.data.sampling_id),
        file_type: this.injectData.data.file_type
   }
      this.checkLimitValid(postValue)
    }else{

    }
      }
  //This is function check update
  upperCheck = (getValue: any) => {
    let randomSelectionValue: any = this.randomSelectForm.value;
    let postValue: any =  {
         lower_limit:parseInt(randomSelectionValue.lowerLimit),
         upper_limit :parseInt(randomSelectionValue.upperLimit),
         sampling_id:parseInt(this.injectData.data.sampling_id),
         file_type: this.injectData.data.file_type
    }
   this.checkLimitValid(postValue)
  }
  // This is function used to check excel sheet data
getUpdateExcelValue(){
  let postValue:any = {
    sampling_id:this.injectData.data.sampling_id,
    file_type:this.injectData.data.file_type,
    client_unq_id: AppConfig.constants['getClientUnqId']
  }
  this.dataService.postData(AppConfig.settings.projects.getExcelValues, postValue, '').subscribe(response => {
    try {
      if(response.status === "true"){
        this.randomSelect = true;
        this.notification.isUILoader(false, this.loginloader, this.commonLoader);
        if(response.maximum_rows_found){
          this.upperValid = null;
          this.randomSelectForm.patchValue({
            lowerLimit: 1,
            upperLimit:response.maximum_rows_found
          });
        }
      }else{
        this.randomSelect = true;
        this.upperValid = true;
        this.notification.isUILoader(false, this.loginloader, this.commonLoader);
      }
    } catch (error) {
            this.logger.error(error);
            this.notification.isUILoader(false, this.loginloader, this.commonLoader);
          }
  })
}
  checkLimitValid(postValue:any){
    this.notification.isUILoader(true, this.loginloader, this.commonLoader);
    if (postValue) {
      this.dataService.postData(AppConfig.settings.projects.VALIDARANDOMSELECTION, postValue, '').subscribe(response => {
        if(response.status === "true"){
        this.notification.isUILoader(false, this.loginloader, this.commonLoader);
          this.upperValid = null;
        }else {
        this.notification.isUILoader(false, this.loginloader, this.commonLoader);
          this.upperValid = true;
          this.notification.isNotification(true, this.translation.instant('Failure'), this.translation.instant(response.message), 'times-circle');
        }
      })
    } else {
      this.upperValid = null;
    }
  }
  getTolerableMiss(event) {
    const tmValue = event.target.value;
    // const splitted = tmValue.split('.', 1);
    const splittedbypercent = tmValue.split('%', 1);
    this.triggerMUSForm.patchValue({
      // tolerableMisstatement: splitted + '.00%'
      tolerableMisstatement:splittedbypercent + '%'
    });
  }

  getCM(event) {
    const cmValue = event.target.value;
    const splitted = cmValue.split('.', 1);
    this.triggerMUSForm.patchValue({
      confidenceLevel: splitted + '.00%'
    });
  }
  /* This is function use to submit MUS form details
  @header: token
  @URL: service API
  @input: file_type:"", sampling_id:"", expected_misstatement:"", tolerable_misstatement:"", confidence_level:"", sampling_based_column:""
  @output: {status:"", message:"", data:[]}
  */
  onSubmitMUSForm() {
    try {
      if (this.triggerMUSForm.valid) {
        this.notification.isUILoader(true, this.loginloader, this.commonLoader);
        this.getTriggerValue = this.triggerMUSForm.value;
        const tolerableMiss = this.getTriggerValue.tolerableMisstatement;
        // const splittedTM = tolerableMiss.split('.', 1);
        const splittedTM = tolerableMiss
        const cmValues = this.getTriggerValue.confidenceLevel;
        const splittedCM = cmValues.split('.', 1);
        this.userTriggerDetail = {
          file_type: this.injectData.data.file_type,
          sampling_id: this.injectData.data.sampling_id,
          expected_misstatement: this.getTriggerValue.expectedMisstatement,
          // eslint-disable-next-line
          // tolerable_misstatement: parseInt(splittedTM, 10),
          tolerable_misstatement: parseFloat(splittedTM),
          // eslint-disable-next-line
          confidence_level: parseInt(splittedCM, 10),
          sampling_based_column: this.getTriggerValue.selectColumn,
          vendor_supplier_confirmation_date: this.getTriggerValue.confirmationDate,
        };
        this.dataService.postData(AppConfig.settings.BALCALCULATION.GETSAMPLESIZE, this.userTriggerDetail, '').subscribe(response => {

          try {
            if (response.status === 'true') {
              this.notification.isUILoader(false, this.loginloader, this.commonLoader);
              this.musTriggerDetails = response.data;
              this.sampleSize = this.musTriggerDetails.sample_size;
              this.triggerMUSDetails = true;
            } else {
              this.notification.isUILoader(false, this.loginloader, this.commonLoader);
              this.triggerMUSDetails = false;
              this.triggerMUSForm.reset();
              this.notification.isNotification(true, this.translation.instant('Failure'), this.translation.instant(response.message), 'times-circle');
            }
          } catch (error) {
            this.logger.error(error);
          }
        });
      } else {
        this.notification.isNotification(true, this.translation.instant('Failure'), this.translation.instant(AppConfig.constants.CONDITIONTEXT.emptyMessageAlert), 'times-circle');
      }
    } catch (error) {
      this.logger.error(error);
    }

  }
  /* This is function use to confirm values details
  @header: token
  @URL: service API
  @input: file_type:"", sampling_id:"", expected_misstatement:"", 
  tolerable_misstatement:"", confidence_level:"", sampling_based_column:""
  @output: {status:"", message:"", data:[]}
  */
  onConfirmValues() {
    try {
      this.notification.isUILoader(true, this.loginloader, this.commonLoader);
      this.getTriggerValue = this.triggerMUSForm.value;
      const tolerableMiss = this.getTriggerValue.tolerableMisstatement;
      // const splittedTM = tolerableMiss.split('.', 1);
      const splittedTM = tolerableMiss
      const cmValues = this.getTriggerValue.confidenceLevel;
      const splittedCM = cmValues.split('.', 1);
      this.userTriggerDetail = {
        file_type: this.injectData.data.file_type,
        sampling_id: this.injectData.data.sampling_id,
        expected_misstatement: this.getTriggerValue.expectedMisstatement,
        // tolerable_misstatement: parseInt(splittedTM, 10),
        tolerable_misstatement: parseFloat(splittedTM),
        confidence_level: parseInt(cmValues, 10),
        sampling_based_column: this.getTriggerValue.selectColumn,
        vendor_supplier_confirmation_date: this.getTriggerValue.confirmationDate
      };
      let sampling_details = JSON.stringify(this.userTriggerDetail);

      this.dataService.postData(AppConfig.settings.BALCALCULATION.MUSSAMPLING, this.userTriggerDetail, '').subscribe(response => {
        try {
          if (response.status === 'true') {
            this.notification.isUILoader(false, this.loginloader, this.commonLoader);
            this.musTriggerDetails = response.data;
            this.sampleSize = this.musTriggerDetails.sample_size;
            this.triggerMUSForm.reset();
            this.router.navigate(['dashboard/monetory-unit-sampling-data'], { queryParams: { "samplingDetails": sampling_details }, skipLocationChange: true, replaceUrl: false });
          } else {
            this.notification.isUILoader(false, this.loginloader, this.commonLoader);
            this.triggerMUSForm.reset();
            this.sampleSize = '';
            this.notification.isNotification(true, this.translation.instant('Failure'), this.translation.instant(response.message), 'times-circle');
          }
        } catch (error) {
          this.logger.error(error);
        }
      });
    } catch (error) {
      this.logger.error(error);
    }

  }
  randomSelectionConfirm = () => {
    try {
      this.notification.isUILoader(true, this.loginloader, this.commonLoader);
      let randomSelectionValue: any = this.randomSelectForm.value;
      if (randomSelectionValue) {
        let postValue: any = {
          sampling_id: parseInt(this.injectData.data.sampling_id),
          file_type: this.injectData.data.file_type,
          client_unq_id : this.injectData.data.client_unq_id,
          lower_limit: parseInt(randomSelectionValue.lowerLimit),
          upper_limit: parseInt(randomSelectionValue.upperLimit),
          num_of_records_required: parseInt(randomSelectionValue.generate)
        }
        this.dataService.postData(AppConfig.settings.projects.GETRANDOMDATA, postValue, '').subscribe(res => {
          try {
            if (res.status === "true") {
              this.upperValid = true;
              this.notification.isUILoader(false, this.loginloader, this.commonLoader);
              AppConfig.constants['randomDetails'] = res.data;
              this.notification.isNotification(true, this.translation.instant('Success'), this.translation.instant(res.message), 'check-square'); 
              this.router.navigate(['dashboard/manual-selection'], { queryParams: { "randomSelectData": JSON.stringify(postValue) }, skipLocationChange: true, replaceUrl: false }); 
              this.randomSelectForm.reset();
              this.cancelBtn();
            } else {
              this.upperValid = true;
              this.notification.isUILoader(false, this.loginloader, this.commonLoader);
              this.notification.isNotification(true, this.translation.instant('Failure'), this.translation.instant(res.message), 'times-circle');
              this.randomSelectForm.reset();
            }
          } catch (error) {
            this.logger.error(error);
          }

        })
      }
    } catch (error) {
      this.logger.error(error);
    }
  }


  generateCheck = (getValue: any) => {
    let randomSelectionValue: any = this.randomSelectForm.value;
    if (parseInt(randomSelectionValue.upperLimit) >= parseInt(getValue.target.value)) {
      this.compUpperValid = false;
    } else {
      this.compUpperValid = true;
    }
  }
  successComputeValue() {
    this.router.navigate(['dashboard/computed-results']);
  }
  deviationComputeValue() {
    this.router.navigate(['dashboard/deviation-in-balance']);
  }

}
