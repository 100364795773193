import { Injectable } from '@angular/core';
import { Observable,of } from 'rxjs';
import { DataService } from 'src/app/data.service';
import { NgiNotificationService } from 'ngi-notification';
import { AuthService } from '../app/services/auth.service';
import { map, catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { AppConfig } from 'src/app/app.config';
import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  Router,
  UrlTree,
} from '@angular/router';
import { HttpClient } from '@angular/common/http';
@Injectable({
    providedIn: 'root'
  })
  export class AuthGuard implements CanActivate {
    constructor(private router: Router, public dataService: DataService,private httpClient: HttpClient,   private authService: AuthService) { }
    canActivate(
        next: ActivatedRouteSnapshot,
        state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
          return this.httpClient.get(environment.HOST +AppConfig.settings.projects.TOKENVALIDATE,{withCredentials:true}).pipe(
            map((res:any) => {
              if (res['status']==="true") {
                if(AppConfig.constants.user_type===res.user_type){
                  return true;
                }
                else{
                  this.router.navigate(['dashboard'])
                  return true;
                } 
              } else {
                this.router.navigate(['login'])
                return false;
              }
            }),
            catchError((err) => {
              return of(false);
            })
          );
        }

  }


