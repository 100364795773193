import { NgModule } from '@angular/core';
import { Routes, RouterModule, Router } from '@angular/router';
import { AuthGuard } from './auth.guard';

const routes = [
  {
    path: '',
    redirectTo: 'product-landing',
    pathMatch: 'full'
  },
  {
    path: 'product-landing',
    loadChildren : () => import('./modules/product/product.module').then(m => m.ProductModule),
  },
  {
    path: 'login',
    loadChildren : () => import('./modules/auth/auth.module').then(m => m.AuthModule)
  },
  {
    path: 'dashboard',
    loadChildren : () => import('./modules/dashboard/dashboard.module').then(m => m.DashboardModule),
    canActivate: [AuthGuard]
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {}
