import { Component, OnInit, Input, Output, EventEmitter, OnDestroy } from '@angular/core';
import { NgiDatatableService } from './ngi-datatable.service';
import { HttpParams } from '@angular/common/http';
import { SharedService } from 'src/app/shared/shared.service';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { DataService } from 'src/app/data.service';
import { AppConfig } from 'src/app/app.config';
import { NgiNotificationService } from 'ngi-notification';
import { NgiLoggerService } from 'ngi-logger';
import { TranslateService } from  '@ngx-translate/core';
@Component({
  selector: 'lib-ngi-datatable',
  templateUrl: './ngi-datatable.component.html',
  styleUrls: ['./ngi-datatable.component.scss']
})
export class NgiDatatableComponent implements OnInit, OnDestroy {
  
  @Input('config') config;
  @Input() choosedLang : String;
  @Output() viewEvent: EventEmitter<any> = new EventEmitter();
  @Output() editEvent: EventEmitter<any> = new EventEmitter();
  @Output() disableEvent: EventEmitter<any> = new EventEmitter();
  @Output() resendMailEvent: EventEmitter<any> = new EventEmitter();
  @Output() showReference: EventEmitter<any> = new EventEmitter();
  @Output() inviewEvent: EventEmitter<any> = new EventEmitter();
  @Output() addviewEvent: EventEmitter<any> = new EventEmitter();
  @Output() addDigitalSignatureEvent: EventEmitter<any> = new EventEmitter();
  
  @Output() currentPage: EventEmitter<any> = new EventEmitter();
  @Output() viewImageEvent: EventEmitter<any> = new EventEmitter();
  @Output() isViewEvent: EventEmitter<any> = new EventEmitter();
  @Output() addTrigeerMUS: EventEmitter<any> = new EventEmitter();
  @Output() showSavedSamples: EventEmitter<any> = new EventEmitter();
  
  @Output() addDownloadFile: EventEmitter<any> = new EventEmitter();
  @Output() addSendMail: EventEmitter<any> = new EventEmitter();
  @Output() addViewCommonList: EventEmitter<any> = new EventEmitter();
  @Output() addDownloadFileTwo: EventEmitter<any> = new EventEmitter();
  @Output() addDownloadFileThree: EventEmitter<any> = new EventEmitter();
  @Output() addDownloadFileFour: EventEmitter<any> = new EventEmitter();
  @Output() addDownloadFileFive: EventEmitter<any> = new EventEmitter();
  @Output() addDownloadOtherItemsFile: EventEmitter<any> = new EventEmitter();
  @Output() isdonwloadZipFile: EventEmitter<any> = new EventEmitter();
  public pagedItemsHeader: any;
  public recordsPerPage: number;
  public totalRecords: number;
  public totalPages: number;
  public search: any;
  showColumns: any;
  List: any;
  pager: any = {};
  loader = false;
  dtViewClientOptions: any;
  commonLoader = 'none';
  trigerMUS;
  getMail;
  getMUSResult;
  sendMailData:boolean;
  summaryClient: any;
  client_email;
  userDetail = AppConfig.constants.email;
  vendorDetailsList: any;
  statusAgreed = AppConfig.constants.CONDITIONTEXT.COMMONAGREED;
  statusDisagreed = AppConfig.constants.CONDITIONTEXT.COMMONDISAGREED;
  statusPending = AppConfig.constants.CONDITIONTEXT.STATUSMSGPENDING;
  statusAlternativelyTested = AppConfig.constants.CONDITIONTEXT.STATUSMSGALTERNATIVELYTESTED;
  statusSettled = AppConfig.constants.CONDITIONTEXT.STATUSMSGSETTLED;
  statusDeviation = AppConfig.constants.CONDITIONTEXT.STATUSMSGDEVIATION;
  statusCompleted = AppConfig.constants.CONDITIONTEXT.STATUSMSGCOMPLETED;
  saved_data: { data: any; saved_selection_type: any; };
  constructor(public translation:  TranslateService,private datatableservice: NgiDatatableService, public dataService: DataService, private sharedService: SharedService, private router: Router,  private notification: NgiNotificationService,private logger: NgiLoggerService,private activatedRoute:ActivatedRoute) {
    sharedService.missionAnnounced$.subscribe(
      data => {
        if (data.startsWith('DISABLEPROJECT')) {
          if (data.indexOf(':') !== -1) {
            const y = data.split(':')[1];
            this.initPage(Number(y));
          }
        } else {
          this.initPage();
        }
      });
  }
  ngOnDestroy() {
    try {
      this.config = {};
    } catch (e) {
    }
this.sendMailData = false;
  }
 
  ngOnInit() {    
    try {
      this.activatedRoute.queryParams.subscribe(params => {
        if (params['client_email']) {
          this.client_email = params['client_email'];
        }
      })
      this.showColumns = this.config.showColumninfo;
      if (this.config.clientMailId === AppConfig.constants.CONDITIONTEXT.clientEmail || this.config.clientMailId === AppConfig.constants.CONDITIONTEXT.archiveDetails) {
        // This is the condition to match with resetclient
        this.initPage(1);
      } 
      else if(this.config.clientMailId === AppConfig.constants.CONDITIONTEXT.resetClient) {
        // This is the condition to match with client email id
        this.initPage(1);
      } 
      else if (this.config.clientMailId === AppConfig.constants.CONDITIONTEXT.common) {
        // This is the condition to match with keyword common
        this.initPage(1);
      } else if (this.config.clientMailId === AppConfig.constants.CONDITIONTEXT.vendorClose) {
        // This is the condition to match with vendor closing balances
        this.initPage(1);
      } else if (this.config.clientMailId === AppConfig.constants.CONDITIONTEXT.supplierClose) {
        // This is the condition to match with supplier closing balance
        this.initPage(1);
      } else if (this.config.clientMailId === AppConfig.constants.CONDITIONTEXT.sendMailVendor){
        // This is the condition to match with sendmail keyword
       
        let getPageNumber=localStorage.getItem('vendorLoginCredentialPageNo')
        
        if (getPageNumber !=='' && getPageNumber !=='0' && getPageNumber != null){
          this.initPage(Number(getPageNumber));
        }
        else{
          this.initPage(1);
        }
      } 
      else if(this.config.clientMailId === AppConfig.constants.CONDITIONTEXT.sendMailSupplier){
        
        let getPageNumber=localStorage.getItem('supplierLoginCredentialPageNo')
        if (getPageNumber !=='' && getPageNumber !=='0' && getPageNumber != null){
          this.initPage(Number(getPageNumber));
        }
        else{
          this.initPage(1);
        }
      }
      else if (this.config.clientMailId === AppConfig.constants.CONDITIONTEXT.clientSummary) {
        // This is the condition to match with clientsummary
        this.initPage(1);
      } else if (this.config.clientMailId === AppConfig.constants.CONDITIONTEXT.argeeDetails) {
        // This is the condition to match with agreedetails keyword
        this.initPage(1);
      } else if (this.config.clientMailId === AppConfig.constants.CONDITIONTEXT.MUSVendorDetails) {
      
        let vendorPageNo= localStorage.getItem('vendorTemplateUpdatedPageNo')
        // condition valid name "MUSVendorDetails" this using most vendor column name, email id and file type
        if (vendorPageNo !=='' && vendorPageNo !=='0' && vendorPageNo !==null){
          this.initPage(Number(vendorPageNo));
        }
        else{
          this.initPage(1)
        }
        
      } else if (this.config.clientMailId === AppConfig.constants.CONDITIONTEXT.MUSSupplierDetails) {
        // This is the condition to match with MUSSupplierDetails keyword
      
        let supplierPageNo=localStorage.getItem('supplierTemplateUpdatedPageNo')
        if (supplierPageNo !=='' && supplierPageNo !=='0' && supplierPageNo !==null){
          this.initPage(Number(supplierPageNo));
        }
        else{
          this.initPage(1)
        }
      } else if (this.config.clientMailId === AppConfig.constants.CONDITIONTEXT.sendMailOther) {
        // This is the condition to match with MUSSupplierDetails keyword
        let supplierPageNo=localStorage.getItem('OtherFilesPageNo')
        if (supplierPageNo !=='' && supplierPageNo !=='0' && supplierPageNo !==null){
          this.initPage(Number(supplierPageNo));
        }
        else{
          this.initPage(1)
        }
      } else if (this.config.clientMailId === AppConfig.constants.CONDITIONTEXT.noSearchPage) {
        // This is the condition to match with onSearchPage
        this.initPage();
      } else if (this.config.clientMailId === AppConfig.constants.CONDITIONTEXT.MUSResult) {
        // This is the condition to match with onSearchPage
        this.initPage();
      }
    } catch (error) {
      this.logger.error(error);
    }
  }
  // This is the function used to search the column keywords
  searchData(keyword) {
    if (keyword !== null || keyword !== undefined) {
      this.initPage(1, keyword);
    }
  }
  
  // This is the function used to common datatable functions
  initPage(page: number = 1, keyword: string = '') {
    try {
      this.commonLoader = 'block';
      // Initialize Params Object
      let ParamsHttp = new HttpParams();
      Object.keys(this.config.params).forEach((key) => {
        ParamsHttp = ParamsHttp.append(key, this.config.params[key]);
      });
      /* This is the condition to match with clientmail keyword
      @header: token,
      @URL: service API,
      @input:page_no:"", search:"", client_email:""
      @output: {status:"", message:"", data:[]}
      */
      if(this.config.clientMailId === AppConfig.constants.CONDITIONTEXT.MUSVendorDetails) {
        localStorage.setItem('vendorTemplateUpdatedPageNo', String(page));
      }
      if(this.config.clientMailId === AppConfig.constants.CONDITIONTEXT.MUSSupplierDetails) {
        localStorage.setItem('supplierTemplateUpdatedPageNo', String(page));
      }
      if (this.config.clientMailId === AppConfig.constants.CONDITIONTEXT.sendMailVendor){
        localStorage.setItem('vendorLoginCredentialPageNo', String(page));
      }
      if (this.config.clientMailId === AppConfig.constants.CONDITIONTEXT.sendMailSupplier){
        localStorage.setItem('supplierLoginCredentialPageNo', String(page));
      }
      if (this.config.clientMailId === AppConfig.constants.CONDITIONTEXT.sendMailOther){
        localStorage.setItem('OtherFilesPageNo', String(page));
      }
      if(this.config.clientMailId === AppConfig.constants.CONDITIONTEXT.resetClient){
        ParamsHttp = ParamsHttp.append('page_no', String(page));
        ParamsHttp = ParamsHttp.append('search', keyword);
        ParamsHttp = ParamsHttp.append('client_id', AppConfig.constants['RESETMUS']);
        ParamsHttp = ParamsHttp.append('client_unq_id', AppConfig.constants['getClientUnqId']);
      
        this.datatableservice.getData(this.config.url, ParamsHttp,'')
          .subscribe(res => {
            this.getResponseDetails(res, page);
          });
      }
      if (this.config.clientMailId === AppConfig.constants.CONDITIONTEXT.clientEmail) {
        ParamsHttp = ParamsHttp.append('page_no', String(page));
        ParamsHttp = ParamsHttp.append('search', keyword);
        ParamsHttp = ParamsHttp.append('client_id', AppConfig.constants['TRIGGERMUS']);
        ParamsHttp = ParamsHttp.append('client_unq_id', AppConfig.constants['getClientUnqId']);
        this.datatableservice.getData(this.config.url, ParamsHttp,'')
          .subscribe(res => {
            this.getResponseDetails(res, page);
          });
      } else if (this.config.clientMailId === AppConfig.constants.CONDITIONTEXT.common) {
        /* This is the condition to match with common keyword
         @header: token,    @URL: service API,   @input:page_no:"", search:"" @output: {status:"", message:"", data:[]}
     */
        ParamsHttp = ParamsHttp.append('page_no', String(page));
        ParamsHttp = ParamsHttp.append('search', keyword);
        this.datatableservice.getData(this.config.url, ParamsHttp,'')
          .subscribe(res => {
            this.getResponseDetails(res, page);
          });
      } else if (this.config.clientMailId === AppConfig.constants.CONDITIONTEXT.sendMailVendor || this.config.clientMailId === AppConfig.constants.CONDITIONTEXT.sendMailSupplier || this.config.clientMailId === AppConfig.constants.CONDITIONTEXT.sendMailOther) {
        /* This is the condition to match with clientmail keyword
        @header: token,    @URL: service API,   @input:page_no:"", search:"", client_email:"", user_type:"" @output: {status:"", message:"", data:[]} */
        
        ParamsHttp = ParamsHttp.append('page_no', String(page));
        ParamsHttp = ParamsHttp.append('search', keyword);
        ParamsHttp = ParamsHttp.append('client_id', AppConfig.constants['SENDCLIENTMAIL']);
        ParamsHttp = ParamsHttp.append('client_unq_id', AppConfig.constants['getClientUnqId']);
        ParamsHttp = ParamsHttp.append('user_type', this.config.userTypeView);
        this.datatableservice.getData(this.config.url, ParamsHttp,'')
          .subscribe(res => {
            this.getResponseDetails(res, page);
          });
      } else if (this.config.clientMailId === AppConfig.constants.CONDITIONTEXT.archiveDetails) {
        /* This is the condition to match with vendorClose keyword
        @header: token,    @URL: service API,   @input:page_no:"", search:"", client_email:"", user_type:"", sampling_based_column:""
        @output: {status:"", message:"", data:[]} */
        this.vendorDetailsList = {
          page_no: page,
          user_type: this.config.userTypeView,
          client_id: AppConfig.constants['getClientUnqId'],
          client_unq_id: AppConfig.constants['getClientUnqId'],
          search: keyword,
          file_type: this.config.userTypeView
        };
        this.dataService.postAllData(this.config.url, ParamsHttp, this.vendorDetailsList,'')
          .subscribe(res => {
            this.getResponseDetails(res, page);
          });
      } else if (this.config.clientMailId === AppConfig.constants.CONDITIONTEXT.supplierClose || this.config.clientMailId === AppConfig.constants.CONDITIONTEXT.vendorClose ) {
        /* This is the condition to match with supplierClose keyword
          @header: token,    @URL: service API,   @input:page_no:"", search:"", client_email:"", user_type:"", sampling_based_column:""
          @output: {status:"", message:"", data:[]} */
        this.vendorDetailsList = {
          page_no: page,
          search: keyword,
          user_type: this.config.userTypeView,
          client_id: AppConfig.constants['TRIGGERMUS'],
          client_unq_id: AppConfig.constants['getClientUnqId']
        };
        this.dataService.postAllData(this.config.url, ParamsHttp, this.vendorDetailsList, '')
          .subscribe(res => {
            this.getResponseDetails(res, page);
          });
      } else if (this.config.clientMailId === AppConfig.constants.CONDITIONTEXT.clientSummary) {
        // this.summaryClient = JSON.parse(this.getTrigerDetails);
        /* This is the condition to match with clientSummary keyword
        @header: token,    @URL: service API,   @input: page_no:"", search:"", client_email:""
        @output: {status:"", message:"", data:[]} */
        
        ParamsHttp = ParamsHttp.append('page_no', String(page));
        ParamsHttp = ParamsHttp.append('search', keyword);
        ParamsHttp = ParamsHttp.append('client_id', AppConfig.constants['TRIGGERSUMMARY']);
        ParamsHttp = ParamsHttp.append('client_unq_id', AppConfig.constants['getClientUnqId']);
        this.datatableservice.getData(this.config.url, ParamsHttp,'')
          .subscribe(res => {
            this.getResponseDetails(res, page);
          });
      } else if (this.config.clientMailId === AppConfig.constants.CONDITIONTEXT.argeeDetails) {
        /* This is the condition to match with argeeDetails keyword. used to get page_no, search, client_email, and file_type relative data only show
        @header: token,    @URL: service API,   @input:page_no:"", search:"", client_email:"", file_type:""
        @output: {status:"", message:"", data:[]} */
        ParamsHttp = ParamsHttp.append('page_no', String(page));
        ParamsHttp = ParamsHttp.append('search', keyword);
        ParamsHttp = ParamsHttp.append('client_id', AppConfig.constants['GETAGREEDISAGREE']);
        ParamsHttp = ParamsHttp.append('client_unq_id', AppConfig.constants['getClientUnqId']);
        ParamsHttp = ParamsHttp.append('file_type', this.config.userTypeView);
        this.dataService.postData(this.config.url, ParamsHttp,'')
          .subscribe(res => {
            this.getResponseDetails(res, page);
          });
      } else if (this.config.clientMailId === AppConfig.constants.CONDITIONTEXT.MUSVendorDetails) {
        /* This is the condition to match with MUSVendorDetails keyword
        @header: token,    @URL: service API,   @input:page_no:"", search:"", client_email:"", user_type:""
        @output: {status:"", message:"", data:[]} */
        this.vendorDetailsList = {
          page_no: page,
          search: keyword,
          user_type: this.config.userTypeView,
          client_id:AppConfig.constants['TRIGGERMUS'],
          client_unq_id: AppConfig.constants['getClientUnqId']
        };
        this.dataService.postAllData(this.config.url, ParamsHttp, this.vendorDetailsList,'')
          .subscribe(res => {
            this.getResponseDetails(res, page);
          });
      } else if (this.config.clientMailId === AppConfig.constants.CONDITIONTEXT.MUSSupplierDetails) {
        /* This is the condition to match with MUSSupplierDetails keyword
          @header: token,    @URL: service API,   @input:page_no:"", search:"", client_email:"", user_type:""
          @output: {status:"", message:"", data:[]} */
        this.vendorDetailsList = {
          page_no: page,
          search: keyword,
          user_type: this.config.userTypeView,
          client_id:AppConfig.constants['TRIGGERMUS'],
          client_unq_id: AppConfig.constants['getClientUnqId']
        };
        this.dataService.postAllData(this.config.url, ParamsHttp, this.vendorDetailsList,'')
          .subscribe(res => {
            this.getResponseDetails(res, page);
          });
      } else if (this.config.clientMailId === AppConfig.constants.CONDITIONTEXT.noSearchPage) {
        /* This is the condition to match with noSearchPage keyword. used to only type params.
          @header: token,    @URL: service API,   @input: type:""
          @output: {status:"", message:"", data:[]} */
        ParamsHttp = ParamsHttp.append('page_no', '');
        ParamsHttp = ParamsHttp.append('search', '');
        ParamsHttp = ParamsHttp.append('search', '');
        ParamsHttp = ParamsHttp.append('type', 'recently_purchased');
        this.datatableservice.getData(this.config.url, ParamsHttp,'')
          .subscribe(res => {
            this.getResponseDetails(res, page);
          });
      } else if (this.config.clientMailId === AppConfig.constants.CONDITIONTEXT.MUSResult) {
        /* This is the condition to match with noSearchPage keyword. used to only type params.
          @header: token,    @URL: service API,   @input: type:""
          @output: {status:"", message:"", data:[]} */
        this.getMUSResult = {
          page_no: String(page),
          search: keyword,
          file_type: this.config.file_type,
          sampling_id: this.config.sampling_id
         
        };
        this.dataService.postData(this.config.url, this.getMUSResult,'')
          .subscribe(res => {
            this.getResponseDetails(res, page);
          });
      }
    } catch (error) {
      this.logger.error(error);
    }
  }
  
  // This is the function is get response page no and details.
  getResponseDetails(res, page) {
  
    try {
      this.List = res;
      // console.log("pagedItemsHeader",res);
      if (this.List.status === 'true') {
        this.commonLoader = 'none';
        this.loader = false;
        this.pagedItemsHeader = res.data;
        this.recordsPerPage = res.per_page;
        this.totalRecords = res.total;
        this.pager = this.datatableservice.getPager(res.total, page, res.per_page);
        this.currentPage.emit(this.pager);
        this.sendMailData = true;
      } else if (res.status === 'false') {
        this.commonLoader = 'none';
        this.pagedItemsHeader = [];
        this.recordsPerPage = 0;
        this.totalRecords = 0;
        this.loader = false;
        this.sendMailData = false;
      } else {
        this.notification.isNotification(true, 'Failure', res.message, 'times-circle');
      }
    } catch (error) {
      this.logger.error(error);
    }
  }

  // get supplier deviation status bast color change
  getColor(supplierDeviationStatus) {
    switch (supplierDeviationStatus) {
      case 'success':
        return AppConfig.constants.COLORCODE.successColor;
      case 'deviation':
        return AppConfig.constants.COLORCODE.deviationColor;
    }
  }
  // get vendor deviation status bast color change
  getVendorColor(vendorDeviationStatus) {
    switch (vendorDeviationStatus) {
      case 'success':
        return AppConfig.constants.COLORCODE.successColor;
      case 'deviation':
        return AppConfig.constants.COLORCODE.deviationColor;
    }
  }
  createNewClient() {
    this.router.navigate(['/dashboard/register-new-client']);
  }
  // view summary details
  viewSummary(data, pageNo) {
    this.trigerMUS = {
      page_no: pageNo,
      client_id: data.id
    };
  }
  // view details
  viewDetails(data, pageNo) {
    this.getMail = data.id;
    this.trigerMUS = {
      page_no: pageNo,
      client_id: this.getMail,
      client_email:data.email,
      client_unq_id:data.client_id
    };
    this.addViewCommonList.emit(this.trigerMUS);
  }

  // this all emit data function start
  downloadFile(data) { this.addDownloadFile.emit(data); }
  downloadFileTwo(data) { this.addDownloadFileTwo.emit(data); }
  downloadFileThree(data) { this.addDownloadFileThree.emit(data); }
  downloadFileFour(data) { this.addDownloadFileFour.emit(data); }
  downloadFileFive(data) { this.addDownloadFileFive.emit(data); }
  downloadFileOtherItemsList(data) { this.addDownloadOtherItemsFile.emit(data);}
  sendMail(data) { 
    this.addSendMail.emit(data); }
  triggerMUS(data) { 
    this.addTrigeerMUS.emit(data); }
  showSavedSamplesdata(data,saved_selection_type)
  {
    this.saved_data = {
      data: data,
      saved_selection_type: saved_selection_type
    };
    this.showSavedSamples.emit(this.saved_data);
  }
  view(data) { 
    this.viewEvent.emit(data);
   }
  edit(data) { this.editEvent.emit(data); }
  showAuditorReference(data){     
    this.showReference.emit(data); }
  disable(data) {this.disableEvent.emit(data); }
  inview(data) { this.inviewEvent.emit(data); }
  addEvent() { this.addviewEvent.emit(null); }
  uploadDigitalSignEvent(){
    this.addDigitalSignatureEvent.emit(null);
  }
  imageview(data) { this.viewImageEvent.emit(data); }
  isView(data) { this.isViewEvent.emit(data); }
  donwloadZipFile(data) { this.isdonwloadZipFile.emit(data); }
  resendAccountSetup(data){
    this.resendMailEvent.emit(data);
  }
  
  // this all emit data function end
}
