import { environment } from '../environments/environment';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { DataService } from 'src/app/data.service';
@Injectable()
export class AppConfig {
  static settings;
  static constants;
  static loginUserDetails: any;
  public getUserInfo: any;
  constructor(private http: HttpClient, public dataService: DataService) {  }

  /*
  The initializerconfig function user config reset API Service json file link here
  @JSON File: config.dev.json and config.prod.json
  */
  async initializerConfig() {
    const configURL = `${environment.HOST}/app_module/get-config-data`;
    return await this.http.get(configURL,{withCredentials:true}).toPromise().then(data => {
      AppConfig.settings = data['data'];
    });
  }
 /*
  The constantsConfig function common constants file
  @JSON File: constants.dev.json and constants.prod.json
  */
  async constantsConfig() {
    const constantsURL= `${environment.HOST}/app_module/get-constant-data`;
    return await this.http.get(constantsURL,{withCredentials:true}).toPromise().then(data => {
      AppConfig.constants =  data['data'];
    });
  }
}
