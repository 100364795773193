import { Component, OnInit, Input, Output, EventEmitter, Injector, AfterViewInit } from '@angular/core';
import { FormGroup, FormBuilder, FormControl, FormArray, Validators} from '@angular/forms';
import { Routes, RouterModule, Router } from '@angular/router';
import { AppConfig } from 'src/app/app.config';
import { DataService } from 'src/app/data.service';
import { NgiNotificationService } from 'ngi-notification';
import { SharedService } from 'src/app/shared.service';
import { HttpParams } from '@angular/common/http';
import { TranslateService } from  '@ngx-translate/core';
import { NgiLoggerService } from 'ngi-logger';

@Component({
  selector: 'app-alert-message',
  templateUrl: './alert-message.component.html',
  styleUrls: ['./alert-message.component.scss']
})
export class AlertMessageComponent implements OnInit {
  @Output('CloseWidget') CloseWidget: EventEmitter<any> = new EventEmitter();
  
  injectData: any;
  getAlertMessage1;
  getAlertMessage2;
  getAlertMessage3;
  getResetList;
  getIdValues;
  getClientId;
  commonLoader = AppConfig.constants.COLORCODE.commonloadcolor; // Loader Icon color
  loginloader: string = this.randomGenerator();  // loader variable declare
  constructor(public translation:  TranslateService,private formBuilder: FormBuilder, private sharedService: SharedService, private fb: FormBuilder, private router: Router, private notification: NgiNotificationService, public dataService: DataService, private injector: Injector, private logger: NgiLoggerService) {
    this.injectData = this.injector.get('injectData');
 }

 /* Ramdom gerator function using loading time, alignment center */
 randomGenerator(): string {
  return Math.floor(Math.random() * 100) + 2 + '' + new Date().getTime() + Math.floor(Math.random() * 100) + 2 + (Math.random().toString(36).replace(/[^a-zA-Z]+/g, '').substr(0, 5));
}
  ngOnInit() {
    try {
    if (this.injectData.getData.action === 'clientActive') {
      this.getAlertMessage1 = this.injectData.getData.getAlertMessage1;
      this.getAlertMessage2 = this.injectData.getData.getAlertMessage2;
    } else if (this.injectData.getData.action === 'resetFiles') {
      this.getAlertMessage1 = this.injectData.getData.getAlertMessage1;
      this.getAlertMessage2 = this.injectData.getData.getAlertMessage2;
      this.getAlertMessage3 = this.injectData.getData.getAlertMessage3;
    } else if (this.injectData.getData.action === 'sendFiles') {
      this.getAlertMessage1 = this.injectData.getData.getAlertMessage1;
      this.getAlertMessage3 = this.injectData.getData.getAlertMessage2;
    } else if (this.injectData.action === 'auditorActiveDetails') {
      this.getAlertMessage1 = this.injectData.getData.getAlertMessage1;
    } else if (this.injectData.action === 'clientDetails') {
      this.getAlertMessage1 = this.injectData.getData.getAlertMessage1;
      this.getAlertMessage2 = this.injectData.getData.getAlertMessage2;
      this.getAlertMessage3 = this.injectData.getData.getAlertMessage3;
    }
    else if(this.injectData.action ==='auditorresendAccountSetupDetails'){
      this.getAlertMessage1 = this.injectData.getData.getAlertMessage;
    }
  } catch (error) {
    this.logger.error(error);
  }
  }
  cancelBtn() {
    this.CloseWidget.emit('CloseWidget');
  }

onTiggerClient() {
  try {
  if (this.injectData.getData.action === 'clientActive') {
    this.getClientId = this.injectData.getData.client_id;
    this.deleteClient(this.injectData.getData, this.getClientId);
  } else if (this.injectData.getData.action === 'resetFiles') {
    this.getResetDetails(this.injectData.getData.getValue);
  }  else if (this.injectData.getData.action === 'sendFiles') {
    this.getSendToAuditor(this.injectData.getData);
  } else if (this.injectData.action === 'auditorActiveDetails') {
    this.getClientId = this.injectData.getData.auditor_id;
    this.deleteClient(this.injectData.getData, this.getClientId);
  }  else if (this.injectData.action === 'clientDetails') {
    this.deleteClientDetails(this.injectData.getData);
  } 
  else if (this.injectData.action === 'auditorresendAccountSetupDetails') {
    this.resendAccountSetupLink(this.injectData.getData);
  }
} catch (error) {
  this.logger.error(error);
}
}
  /*
  This is function using active or in-active client details
  @header: token, URL: services API,
  @input: client_id:"", status:""
  @output: {status:"", message:"", data:[]}
  */
 deleteClient(data, getId) {
  try {
  this.notification.isUILoader(true, this.loginloader, this.commonLoader);
  let Params = new HttpParams();
  if (this.injectData.getData.action === 'clientActive') {
    Params = Params.append('client_id', getId);
    Params = Params.append('status', data.status);
    Params = Params.append('client_unq_id', data.client_unq_id);
  } else if (this.injectData.action === 'auditorActiveDetails') {
    Params = Params.append('auditor_id', getId);
    Params = Params.append('status', data.status);
    Params = Params.append('client_unq_id', data.client_unq_id);
  }
  this.dataService.getDataPageNo(data.url, Params, '').subscribe(respone => {
  try {
    if (respone.status === 'true') { // response successfully
      this.notification.isUILoader(false, this.loginloader, this.commonLoader);
      this.sharedService.announceMission('Successupdate'); // This is the message send to client datatable details response message
      
      this.notification.isNotification(true, this.translation.instant('Success'), this.translation.instant(respone.message), 'check-square');
      this.cancelBtn();
    } else { // response failed
      this.notification.isUILoader(false, this.loginloader, this.commonLoader);
      this.notification.isNotification(true, this.translation.instant('Failure'), this.translation.instant(respone.message), 'times-circle');
      this.cancelBtn();
    }
  } catch (error) {
    this.logger.error(error);
  }
}); } catch (error) {
  this.logger.error(error);
}

}

// This is the function used to reset upload client files
// @header: token, URL: services API,
// @input: client_id:"", status:""
// @output: {status:"", message:"", data:[]}
getResetDetails(getValue) {
  try {
  this.getResetList = {
    client_id: getValue.client_id,
    file_id: getValue.id,
    client_unq_id:getValue.client_unq_id
    };
  this.notification.isUILoader(true, this.loginloader, this.commonLoader);
  this.dataService.postData(AppConfig.settings.AUDITORAPI.RESETFILES, this.getResetList, '').subscribe(respone => {
    try {
        if (respone.status === 'true') {
          this.notification.isUILoader(false, this.loginloader, this.commonLoader);
          this.notification.isNotification(true, this.translation.instant('Success'), this.translation.instant(respone.message), 'check-square');
          this.router.navigate(['dashboard/file-management']);
        } else {
          this.notification.isUILoader(false, this.loginloader, this.commonLoader);
          this.notification.isNotification(true, this.translation.instant('Failure'), this.translation.instant(respone.message), 'times-circle');
          this.router.navigate(['dashboard/file-management']);
        }
    } catch (error) {
      this.logger.error(error);
    }
 }); } catch (error) {
  this.logger.error(error);
}
}
  /*
  This is the function used for sending mail to auditor after clicking yes button in popup box.
  @header:token, URL: services API,
  @input:{file_id:""}
  @output:{status:"", message:"", data:[]}
 */
getSendToAuditor(getDatas) {
  try {
  this.getIdValues = {
    file_id: getDatas.file_id
  };
  this.notification.isUILoader(true, this.loginloader, this.commonLoader);
  this.dataService.postData(AppConfig.settings.BALCALCULATION.SENDTOAUDITOR, this.getIdValues, '').subscribe(response => {
    try {
      if (response.status === 'true') {
        this.notification.isUILoader(false, this.loginloader, this.commonLoader);
        this.sharedService.announceMission('Successupdate'); // This is the message send to client datatable details response message
        this.notification.isNotification(true, this.translation.instant('Success'), this.translation.instant(response.message), 'check-square');
        this.cancelBtn();
      } else {
        this.notification.isUILoader(false, this.loginloader, this.commonLoader);
        this.notification.isNotification(true, this.translation.instant('Failure'), this.translation.instant(response.message), 'times-circle');
        this.cancelBtn();
      }
    } catch (error) {
      this.logger.error(error);
    }
  }); } catch (error) {
    this.logger.error(error);
  }
}

  /*
  This is the function used for sending mail to auditor after clicking yes button in popup box.
  @header:token, URL: services API,
  @input:{file_id:""}
  @output:{status:"", message:"", data:[]}
 */
  deleteClientDetails(getDatas) {
    try {
    let postValue:any = {
      client_id:getDatas.client_id,
      client_unq_id:getDatas.client_unq_id
    };
    this.notification.isUILoader(true, this.loginloader, this.commonLoader);
    this.dataService.getDataPageNo(AppConfig.settings.projects.DELETECLIENT, postValue, '').subscribe(response => {
      try {
        if (response.status === 'true') {
          this.notification.isUILoader(false, this.loginloader, this.commonLoader);
          this.sharedService.announceMission('Successupdate'); // This is the message send to client datatable details response message
          this.notification.isNotification(true, this.translation.instant('Success'), this.translation.instant(response.message), 'check-square');
          this.cancelBtn();
        } else {
          this.notification.isUILoader(false, this.loginloader, this.commonLoader);
          this.notification.isNotification(true, this.translation.instant('Failure'), this.translation.instant(response.message), 'times-circle');
          this.cancelBtn();
        }
      } catch (error) {
        this.logger.error(error);
      }
    }); } catch (error) {
      this.logger.error(error);
    }
  }
  resendAccountSetupLink(getDatas) {
    try {
    let postValue:any = {
      email:getDatas.email
    };
    this.notification.isUILoader(true, this.loginloader, this.commonLoader);
    this.dataService.postData(AppConfig.settings.projects.resendAccountSetupLinktoAuditor, postValue, '').subscribe(response => {
      try {
        if (response.status === 'true') {
          this.notification.isUILoader(false, this.loginloader, this.commonLoader);
          this.sharedService.announceMission('Successupdate'); // This is the message send to client datatable details response message
          this.notification.isNotification(true, this.translation.instant('Success'), this.translation.instant(response.message), 'check-square');
          this.cancelBtn();
        } else {
          this.notification.isUILoader(false, this.loginloader, this.commonLoader);
          this.notification.isNotification(true, this.translation.instant('Failure'), this.translation.instant(response.message), 'times-circle');
          this.cancelBtn();
        }
      } catch (error) {
        this.logger.error(error);
      }
    }); } catch (error) {
      this.logger.error(error);
    }
  }
}
