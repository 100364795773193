import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-settingsnavbar',
  templateUrl: './settingsnavbar.component.html',
  styleUrls: ['./settingsnavbar.component.scss']
})
export class SettingsnavbarComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
