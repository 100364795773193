import { Component, OnInit, Injector, EventEmitter, Output } from '@angular/core';
import { Routes, RouterModule, Router } from '@angular/router';
import { DataService } from 'src/app/data.service';
import { AppConfig } from 'src/app/app.config';
import { NgiNotificationService } from 'ngi-notification';
import { NgiLoggerService } from 'ngi-logger';
import { TranslateService } from  '@ngx-translate/core';

@Component({
  selector: 'app-calculated-interpolation-summary-details',
  templateUrl: './calculated-interpolation-summary-details.component.html',
  styleUrls: ['./calculated-interpolation-summary-details.component.scss']
})
export class CalculatedInterpolationSummaryDetailsComponent implements OnInit {
  injectData: any;
  status:any;
  fileType:any;
  MUSInterpolationCalculatedSummaryDetails:any;
  expectedMisstatement:any;
  populationValue:any;
  tolerableMisstatement:any;
  commonLoader = AppConfig.constants.COLORCODE.commonloadcolor; // common loader color code
  sampleSize:any;
  totalUnderStatement:any;
  totalOverStatement:any;
  tableLoad: boolean;
  tableLoader:boolean;
  materiality:any;
  getAlertMessage1;
  getAlertMessage2;
  loginloader: string = this.randomGenerator();  // Loader variable declare
  @Output('CloseWidget') CloseWidget: EventEmitter<any> = new EventEmitter();

  constructor(public translation:  TranslateService,private injector: Injector,private router: Router,public dataService: DataService,private notification: NgiNotificationService, private logger: NgiLoggerService) {
    this.injectData = this.injector.get('injectData');
    this.fileType = this.injectData.postData.file_type;
    this.tableLoad = false;
   }

  ngOnInit() {
    this.getAlertMessage1 = this.injectData.getData.getAlertMessage1;
    this.getAlertMessage2 = this.injectData.getData.getAlertMessage2;
    if (this.injectData.action === 'ViewInterpolationSummaryDetails'){
      this.viewCalculatedInterpolationSummaryList();
    }
    
    
  }
  randomGenerator(): string {
    return Math.floor(Math.random() * 100) + 2 + '' + new Date().getTime() + Math.floor(Math.random() * 100) + 2 + (Math.random().toString(36).replace(/[^a-zA-Z]+/g, '').substr(0, 5));
  }
  

 viewCalculatedInterpolationSummaryList()
  {

    try {
      this.tableLoader = true;
      this.dataService.postData(AppConfig.settings.BALCALCULATION.CALCULATEDINTERPOLATIONSUMMARY, this.injectData.postData, '').subscribe(response => {
        try {
          if (response.status === 'true') { // response successfully
            this.notification.isUILoader(false, this.loginloader, this.commonLoader);
            this.MUSInterpolationCalculatedSummaryDetails = response.data;
            this.tableLoad = true;
            this.tableLoader = false;
            this.expectedMisstatement = this.MUSInterpolationCalculatedSummaryDetails[0].expected_misstatement;
            this.status = this.MUSInterpolationCalculatedSummaryDetails[0].status;
            this.materiality = this.MUSInterpolationCalculatedSummaryDetails[0].materiality;
            this.populationValue = this.MUSInterpolationCalculatedSummaryDetails[0].population_value;
            // this.sampleInterval = this.MUSSummaryDetails.sample_interval;
            this.sampleSize = this.MUSInterpolationCalculatedSummaryDetails[0].sample_size;
            this.tolerableMisstatement = this.MUSInterpolationCalculatedSummaryDetails[0].tolerable_misstatement;
            // this.fileType = this.MUSSummaryDetails.file_type;
            this.totalOverStatement = this.MUSInterpolationCalculatedSummaryDetails[0].total_over_statement;
            this.totalUnderStatement = this.MUSInterpolationCalculatedSummaryDetails[0].total_under_statement;
    
          } else { // response failed
            this.notification.isUILoader(false, this.loginloader, this.commonLoader);
            this.notification.isNotification(true, this.translation.instant('Failure'), this.translation.instant(response.message), 'times-circle');
            this.tableLoad = true;
          }
        } catch (error) {
          this.tableLoad = true;
          this.tableLoader = false;
          this.logger.error(error);
        }
      }); } catch (error) {
        this.tableLoader = false;
        this.logger.error(error);
      }
  }
  cancelBtn() {
    this.CloseWidget.emit('CloseWidget');
    this.router.navigate(['/dashboard/Evaluation']);
  }

}
