import { Component, OnInit, Input, Output, EventEmitter, Injector, AfterViewInit } from '@angular/core';
import { FormGroup, FormBuilder, FormControl, FormArray, Validators} from '@angular/forms';
import { Routes, RouterModule, Router } from '@angular/router';
import { AppConfig } from 'src/app/app.config';
import { DataService } from 'src/app/data.service';
import { NgiNotificationService } from 'ngi-notification';
import { SharedService } from 'src/app/shared.service';
import { NgiLoggerService } from 'ngi-logger';
import { TranslateService } from  '@ngx-translate/core';

@Component({
  selector: 'app-delete-client-upload-files',
  templateUrl: './delete-client-upload-files.component.html',
  styleUrls: ['./delete-client-upload-files.component.scss']
})
export class DeleteClientUploadFilesComponent implements OnInit {
  @Output() addMailData: EventEmitter<any> = new EventEmitter();
  @Output('CloseWidget') CloseWidget: EventEmitter<any> = new EventEmitter();
  
  injectData: any;
  getAlertMessage1;
  getIdValues;
  commonLoader = AppConfig.constants.COLORCODE.commonloadcolor; // Loader Icon color
  loginloader: string = this.randomGenerator();  // loader variable declare
  constructor(public translation:  TranslateService,private formBuilder: FormBuilder, private sharedService: SharedService, private fb: FormBuilder, private router: Router, private notification: NgiNotificationService, public dataService: DataService, private injector: Injector, private logger: NgiLoggerService) {
    this.injectData = this.injector.get('injectData');
 }
 /* Ramdom gerator function using loading time, alignment center */
 randomGenerator(): string {
  return Math.floor(Math.random() * 100) + 2 + '' + new Date().getTime() + Math.floor(Math.random() * 100) + 2 + (Math.random().toString(36).replace(/[^a-zA-Z]+/g, '').substr(0, 5));
}
  ngOnInit() {
    this.getAlertMessage1 = this.injectData.getData.getAlertMessage1;
  }
  cancelBtn() {
    this.CloseWidget.emit('CloseWidget');
}
onTiggerClient() {
  if(this.injectData.action === 'deleteClientFiles'){
    this.getDeleteFields(this.injectData.getData);
  }else if(this.injectData.action === 'deleteAuditorFiles'){
  this.deleteAuditorFile(this.injectData.getData)
  }
}

deleteAuditorFile(getData?:any){
  try {
    this.getIdValues = {
      client_id: getData.client_id,
      uploaded_id: getData.uploaded_id
    };
    this.notification.isUILoader(true, this.loginloader, this.commonLoader);
    this.dataService.postWithOutTocken(AppConfig.settings.projects.deleteAuditorFile, this.getIdValues).subscribe(response => {
      try {
        if (response.status === 'true') {
          this.notification.isUILoader(false, this.loginloader, this.commonLoader);
          this.sharedService.announceMission('SuccessAuditorDelete'); // This is the message send to client datatable details response message
          this.notification.isNotification(true, this.translation.instant('Success'), this.translation.instant(response.message), 'check-square');
          this.cancelBtn();
        } else {
          this.notification.isUILoader(false, this.loginloader, this.commonLoader);
          this.notification.isNotification(true, this.translation.instant('Failure'), this.translation.instant(response.message), 'times-circle');
          this.cancelBtn();
        }
      } catch (error) {
        this.logger.error(error);
      }
    });
  } catch (error) {
    this.logger.error(error);
  }
}
/*
  This is the function to delete unwanted file in file management before clicking compute balance
  @header: token, @URL: services api,  @input:{file_id:""},  @output: {status:"", message:"", data:[]}
  */
 getDeleteFields(getIdValues) {
  try {
    this.getIdValues = {
      file_id: getIdValues.file_id
    };
    this.notification.isUILoader(true, this.loginloader, this.commonLoader);
    this.dataService.getURL(AppConfig.settings.BALCALCULATION.FILEDELETE+'/'+this.getIdValues.file_id).subscribe(response => {
  try {
    if (response.status === 'true') {
      this.notification.isUILoader(false, this.loginloader, this.commonLoader);
      this.sharedService.announceMission('SuccessDelete'); // This is the message send to client datatable details response message
      this.notification.isNotification(true, this.translation.instant('Success'), this.translation.instant(response.message), 'check-square');
      this.cancelBtn();
    } else {
      this.notification.isUILoader(false, this.loginloader, this.commonLoader);
      this.notification.isNotification(true, this.translation.instant('Failure'), this.translation.instant(response.message), 'times-circle');
      this.cancelBtn();
    }
  } catch (error) {
    this.logger.error(error);
  }
}); } catch (error) {
  this.logger.error(error);
}
}


}
