import { Component, OnInit, Input, Output, EventEmitter, Injector, AfterViewInit } from '@angular/core';
import { FormGroup, FormBuilder, FormControl, FormArray, Validators} from '@angular/forms';
import { Routes, RouterModule, Router } from '@angular/router';
import { AppConfig } from 'src/app/app.config';
import { DataService } from 'src/app/data.service';
import { NgiNotificationService } from 'ngi-notification';
import { SharedService } from 'src/app/shared.service';
import { HttpParams } from '@angular/common/http';
import { TranslateService } from  '@ngx-translate/core';

import { NgiLoggerService } from 'ngi-logger';

@Component({
  selector: 'app-update-client-details',
  templateUrl: './update-client-details.component.html',
  styleUrls: ['./update-client-details.component.scss']
})
export class UpdateClientDetailsComponent implements OnInit {
  @Output('CloseWidget') CloseWidget: EventEmitter<any> = new EventEmitter();
 
  injectData: any;
  mobileCode;
  countryList;
  getClientData;
  commonLoader = AppConfig.constants.COLORCODE.commonloadcolor; // Loader Icon color
  loginloader: string = this.randomGenerator();  // loader variable declare
  nameTitle: any ;
    /*
  This is a formgroup update client details
  @input:{first_name:"", last_name:"", Mobile_number:"", country_name:"", phone_code:""}
  */
 getUpdateClientData  = this.formBuilder.group({
  salutation: new FormControl(null, [ Validators.required ] ),    
  first_name: ['', [Validators.required, Validators.minLength(AppConfig.settings.formvalidkay.FIRSTNAMEMINIMUM), Validators.maxLength(AppConfig.settings.formvalidkay.FIRSTNAMEMAXIMUM), Validators.pattern(AppConfig.settings.formvalidkay.STRINGPATTAN)]],
  last_name: ['', [Validators.required, Validators.minLength(AppConfig.settings.formvalidkay.LASTNAMEMINIMUM), Validators.maxLength(AppConfig.settings.formvalidkay.LASTNAMEMAXIMUM), Validators.pattern(AppConfig.settings.formvalidkay.CHARACTERSALONGWITHHYPHEN)]],
  email: ['', [Validators.required, Validators.pattern(AppConfig.settings.formvalidkay.EMAILPATTEN)]],
  mobile_number: ['', [
    Validators.required, Validators.minLength(AppConfig.settings.formvalidkay.MOBILEMINIMUM), Validators.maxLength(AppConfig.settings.formvalidkay.MOBILEMAXIMUM), Validators.pattern(AppConfig.settings.formvalidkay.NUMBERPATTEN)
  ]],
  country_name: new FormControl(null, [ Validators.required ] ),
  phone_code: [null, Validators.required],
  company_name:['', [Validators.required, Validators.minLength(AppConfig.settings.formvalidkay.COMMONMIN), Validators.maxLength(AppConfig.settings.formvalidkay.COMMONMAX)]]
});
  constructor(public translation:  TranslateService,private formBuilder: FormBuilder, private sharedService: SharedService, private fb: FormBuilder, private router: Router, private notification: NgiNotificationService, public dataService: DataService, private injector: Injector, private logger: NgiLoggerService) {
    this.injectData = this.injector.get('injectData');
    if(localStorage.getItem('TRANSLATIONLANGINFO') === 'de')
    {
      this.nameTitle = AppConfig.constants.GERMANSALUTATION;
    }else{
      this.nameTitle = AppConfig.constants.ENGSALUTATION;
    }
 }

  ngOnInit() {
    this.getClientList(this.injectData.getData);
    this.getCountryList();
  }
     /* Ramdom gerator function using loading time, alignment center */
     randomGenerator(): string {
      return Math.floor(Math.random() * 100) + 2 + '' + new Date().getTime() + Math.floor(Math.random() * 100) + 2 + (Math.random().toString(36).replace(/[^a-zA-Z]+/g, '').substr(0, 5));
    }
  // get country list details
  getCountryList() {
    try {
    this.dataService.getCountry(AppConfig.settings.projects.countryDetails).subscribe(response => {
      try {
        if (response.status === 'true') {
        this.countryList = response.data;
        this.mobileCode = response.data;
        }
      } catch (error) {
        this.logger.error(error);
      }
    }); } catch (error) {
      this.logger.error(error);
    }
  }
  cancelBtn() {
    this.CloseWidget.emit('CloseWidget');
  }
  getClientList(getClientId) {
    try {
    let Params = new HttpParams();
    Params = Params.append('client_id', getClientId.id);
    Params = Params.append('client_unq_id', getClientId.client_id);
    this.dataService.getDataPageNo(AppConfig.settings.AUDITORAPI.auditorClientEdit, Params, '').subscribe(response => {
     try {
        if (response.status === 'true') { // response successfully
          this.getClientData = response.data[0];
          this.getUpdateClientData.patchValue({
            salutation:this.getClientData.salutation,
            first_name: this.getClientData.first_name,
            last_name: this.getClientData.last_name,
            country_name: this.getClientData.country_name,
            email: this.getClientData.email,
            mobile_number: this.getClientData.mobile_number,
            phone_code: this.getClientData.phone_code,
            company_name: this.getClientData.company_name
          });
        }
      } catch (error) {
        this.logger.error(error);
      }
    }); } catch (error) {
      this.logger.error(error);
    }
  }
   // push country relative mobile code add inpu fields
   getCountryName(event) {
    const mobileCountryCode = event.target.value;
    const splitted = mobileCountryCode.split(': ')[1];
    const getPhoneCode = splitted;
    this.mobileCode.forEach(value => {
      if (value.nicename === getPhoneCode) {
        this.getUpdateClientData.patchValue({
          phone_code: value.phonecode
        });
      }
    });
  }
    /*
  This is function using Update client details
  @header: token, URL: services API,
  @input: first_name:"", last_name:"", Mobile_number:"", country_name:"", phone_code:"".
  @output: {status:"", message:"", data:[]}
  */
 onUpdateClientDetails() {
  try {
    if (this.getUpdateClientData.valid) {
  this.notification.isUILoader(true, this.loginloader, this.commonLoader);
  this.dataService.postData(AppConfig.settings.PROFILESERVICES.UPDATEPROFILECLIENT, this.getUpdateClientData.value, '').subscribe(response => {
  try {
    if (response.status === 'true') {
      this.notification.isUILoader(false, this.loginloader, this.commonLoader);
      this.sharedService.announceMission('Successupdate'); // This is the message send to client datatable details response message
      this.notification.isNotification(true, this.translation.instant('Success'), this.translation.instant(response.message), 'check-square');
      this.cancelBtn();
    } else {
      this.notification.isUILoader(false, this.loginloader, this.commonLoader);
      this.notification.isNotification(true, this.translation.instant('Failure'), this.translation.instant(response.message), 'times-circle');
      this.router.navigate(['dashboard/client-view-page']);
      this.cancelBtn();
    }
  } catch (error) {
    this.logger.error(error);
   }
}); } else {
  this.notification.isNotification(true, this.translation.instant('Failure'),  this.translation.instant(AppConfig.constants.CONDITIONTEXT.emptyMessageAlert), 'times-circle');
}} catch (error) {
  this.logger.error(error);
 }
}
}
