import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders, HttpResponse} from '@angular/common/http';
import {Observable, of, Subject, BehaviorSubject} from 'rxjs';
import { environment } from 'src/environments/environment';
import * as _ from 'underscore';
@Injectable({
  providedIn: 'root'
})


export class DataService {
  isAuthenticate = new BehaviorSubject<boolean>(false);
  constructor(private http: HttpClient) { }

  logout() {
    localStorage.clear();
    this.isAuthenticate.next(false);
  }

// send to url, body, and , token values
postData( url: string, body: any, token?: string): Observable<any> {
    return this.http.post(environment.HOST + url, body, {
      withCredentials:true,
      headers: new HttpHeaders({
        Authorization: token
      })
    });
}


downloadFile(url:string, params?:any, token?: string): Observable<any>{
  return this.http.get(environment.HOST +url, {
    withCredentials:true,
    responseType: 'blob',
    observe: 'response'
  });
}

downloadDashboardFile(url:string, body: any,token?: string): Observable<any>{
  return this.http.post(environment.HOST +url, body, {
    withCredentials:true,
    responseType: 'blob',
    observe: 'response'
  });
}

// send to url, token values. widthout body value
postDataCommon( url: string, token?: string ): Observable<any> {
  return this.http.post(environment.HOST + url, {
  withCredentials:true,
   headers: new HttpHeaders({
     'Content-Type': 'application/json',
     Accept: 'application/json',
     Authorization: token
   })
 });
}

// send to url, token values. Params value
postDataPageNo(url: string, params, token?: string): Observable<any> {
  return this.http.post(environment.HOST + url,
    {
      withCredentials:true,
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: token  }), params
      });
}

// send to url, token values.
postWithOutTocken(url: string, body: any): Observable<any> {
  return this.http.post(environment.HOST + url, body, {
    withCredentials:true,
   headers: new HttpHeaders({
     'Content-Type': 'application/json',
     Accept: 'application/json'
   })
 });
}
// send to url, token values.
getPaymentURL(params): Observable<any> {
  return this.http.get(environment.HOSTPAY, {
    withCredentials:true,
   headers: new HttpHeaders({
    'Content-Type': 'application/x-www-form-urlencoded',
    'Access-Control-Allow-Origin': '*',
    'Access-Control-Allow-Methods' : 'POST, GET',
    Accept: 'application/json'
   }), params
 });
}

postAllData( url: string, params, body: any, token?: string): Observable<any> {
  return this.http.post(environment.HOST + url, body, {
    withCredentials:true,
    headers: new HttpHeaders({
      // 'Content-Type': 'multipart/form-data',
      Authorization: token
    }), params
  });
}
// get url value
getURL(url: string): Observable<any> {
  return this.http.get(environment.HOST + url,
    {
      withCredentials:true,
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Accept: 'application/json'  })
      });
}
// get value to url, token values. widthout params value
getData(url: string, token?: string): Observable<any> {
  return this.http.get(environment.HOST + url,
    {
      withCredentials:true,
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: token  })
      });
}

// get value to url, token values, params value
getDataPageNo(url: string, params?:any, token?: string): Observable<any> {
  return this.http.get(environment.HOST + url,
    {
      withCredentials:true,
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: token  }), params
      });
}

// get country datas
  getCountry(url: string): Observable<any> {
    return this.http.get(environment.HOST + url,
      {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          Accept: 'application/json',
          Authorization: ''  })
        });
  }

getPager(totalItems: number, currentPage: number = 1, pageSize: number = 10) {
  // calculate total pages
  const totalPages = Math.ceil(totalItems / pageSize);
   // tslint:disable-next-line: no-string-literal
  let startPage: number;
  let endPage: number;
  if (totalPages <= 10) {
    // less than 10 total pages so show all
    startPage = 1;
    endPage = totalPages;
  } else {
    // more than 10 total pages so calculate start and end pages
    if (currentPage <= 6) {
      startPage = 1;
      endPage = 10;
    } else if (currentPage + 4 >= totalPages) {
      startPage = totalPages - 9;
      endPage = totalPages;
    } else {
      startPage = currentPage - 5;
      endPage = currentPage + 4;
    }
  }

  // calculate start and end item indexes
  const startIndex = (currentPage - 1) * pageSize;
  const endIndex = Math.min(startIndex + pageSize - 1, totalItems - 1);

  // create an array of pages to ng-repeat in the pager control
  const pages = _.range(startPage, endPage + 1);

  // return object with all pager properties required by the view
  return {
    totalItems,
    currentPage,
    pageSize,
    totalPages,
    startPage,
    endPage,
    startIndex,
    endIndex,
    pages
  };

}

}
