import { Component, OnInit, Input, Output, EventEmitter, Injector, AfterViewInit } from '@angular/core';
import { FormGroup, FormBuilder, FormControl, FormArray, Validators} from '@angular/forms';
import { Routes, RouterModule, Router } from '@angular/router';
import { AppConfig } from 'src/app/app.config';
import { DataService } from 'src/app/data.service';
import { NgiNotificationService } from 'ngi-notification';
import { SharedService } from 'src/app/shared.service';
import { HttpParams } from '@angular/common/http';
import { NgiLoggerService } from 'ngi-logger';
import { TranslateService } from  '@ngx-translate/core';
@Component({
  selector: 'app-auditor-details-update',
  templateUrl: './auditor-details-update.component.html',
  styleUrls: ['./auditor-details-update.component.scss']
})

export class AuditorDetailsUpdateComponent implements OnInit, AfterViewInit {
  @Output('CloseWidget') CloseWidget: EventEmitter<any> = new EventEmitter();
  injectData: any;
  mobilePhCode;
  countryList;
  clientProfileUpdate;
  nameTitle: any = ['Mr.', 'Mrs.', 'Miss.', 'Ms.'];
  default = 'Mr.';
  getAuditorDetails;
  commonLoader = AppConfig.constants.COLORCODE.commonloadcolor; // common loader color code
  loginloader: string = this.randomGenerator();  // Loader variable declare
  auditorDetailsUpdate = this.formBuilder.group({
    title_name: new FormControl(null, [ Validators.required ] ),
        first_name: new FormControl('',  [Validators.required, Validators.minLength(AppConfig.settings.formvalidkay.FIRSTNAMEMINIMUM), Validators.maxLength(AppConfig.settings.formvalidkay.FIRSTNAMEMAXIMUM), Validators.pattern(AppConfig.settings.formvalidkay.STRINGPATTAN)]),
        last_name: new FormControl('',  [Validators.required, Validators.minLength(AppConfig.settings.formvalidkay.LASTNAMEMINIMUM), Validators.maxLength(AppConfig.settings.formvalidkay.LASTNAMEMAXIMUM), Validators.pattern(AppConfig.settings.formvalidkay.CHARACTERSALONGWITHHYPHEN)]),
        email: new FormControl('', [
          Validators.required, Validators.pattern(AppConfig.settings.formvalidkay.EMAILPATTEN)
        ]),
        mobile_number: new FormControl('', [
          Validators.required, Validators.minLength(AppConfig.settings.formvalidkay.MOBILEMINIMUM), Validators.maxLength(AppConfig.settings.formvalidkay.MOBILEMAXIMUM), Validators.pattern(AppConfig.settings.formvalidkay.NUMBERPATTEN)
        ]),
        company_name: new FormControl('', [Validators.required, Validators.minLength(AppConfig.settings.formvalidkay.COMMONMIN), Validators.maxLength(AppConfig.settings.formvalidkay.COMMONMAX)]),
        vat_id: new FormControl(''),
        postal_code: new FormControl('', [Validators.required, Validators.minLength(AppConfig.settings.formvalidkay.POSTALCODEMIN), Validators.maxLength(AppConfig.settings.formvalidkay.POSTALCODEMAX), Validators.pattern(AppConfig.settings.formvalidkay.NUMBERPATTEN)]),
        city: new FormControl('', [Validators.required, Validators.minLength(AppConfig.settings.formvalidkay.COMMONMIN), Validators.maxLength(AppConfig.settings.formvalidkay.COMMONMAX)]),
        street: new FormControl('', [Validators.required, Validators.minLength(AppConfig.settings.formvalidkay.COMMONMIN), Validators.maxLength(AppConfig.settings.formvalidkay.COMMONMAX)]),
        house_no: new FormControl('', [Validators.required, Validators.minLength(AppConfig.settings.formvalidkay.HOUSNOMIN), Validators.maxLength(AppConfig.settings.formvalidkay.HOUSNOMAX)]),
        country_name: new FormControl(null, [ Validators.required ] ),
        phone_code: new FormControl(null, [ Validators.required ] ),
        vat_code: new FormControl(null),
  });
  constructor(public translation:  TranslateService,private formBuilder: FormBuilder, private sharedService: SharedService, private fb: FormBuilder, private router: Router, private notification: NgiNotificationService, public dataService: DataService, private injector: Injector,private logger: NgiLoggerService) {
      this.injectData = this.injector.get('injectData');
   }

  ngOnInit() {
    this.getCountryList();
    this.getAuditorList(this.injectData.data.sampling_id);
  }
  /* This is the function used to edit profile
  @header: token, URL: services API,
  @input:{first_name:'', last_name:'', email:'', mobile_number:'', company_name:'', vat_id:'', postal_code:'', city:'', street:'', house_no:'', phone_code:'', vat_code:''}
  @output: {status:'', message:'', data:[]}
  */
 onProfileSubmit() {
  try {
  this.notification.isUILoader(true, this.loginloader, this.commonLoader);
  this.dataService.postData(AppConfig.settings.PROFILESERVICES.UPDATEPROFILEAUDITOR, this.auditorDetailsUpdate.value, '').subscribe(response => {
try {
  if (response.status === 'true') {  // response successfully
    this.notification.isUILoader(false, this.loginloader, this.commonLoader);
    this.notification.isNotification(true, this.translation.instant('Success'), this.translation.instant(response.message), 'check-square');
    this.sharedService.announceMission('UPDATECLIENT');
    this.router.navigate(['/dashboard']);
  } else { // response Failure
    this.notification.isUILoader(false, this.loginloader, this.commonLoader);
    this.notification.isNotification(true, this.translation.instant('Failure'), this.translation.instant(response.message), 'times-circle');
    this.router.navigate(['/dashboard']);
  }
} catch (error) {
  this.logger.error(error);
}
});
 } catch (error) {
  this.logger.error(error);
}
}
  ngAfterViewInit() {
    this.notification.isUILoader(true, this.loginloader, this.commonLoader);
  }
   // Loader randomgenerator script
   randomGenerator(): string {
    return Math.floor(Math.random() * 100) + 2 + '' + new Date().getTime() + Math.floor(Math.random() * 100) + 2 + (Math.random().toString(36).replace(/[^a-zA-Z]+/g, '').substr(0, 5));
  }
 cancelBtn() {
       this.CloseWidget.emit('CloseWidget');
}
// This is the function to get country list
getCountryList() {
  try {
  this.dataService.getCountry(AppConfig.settings.projects.countryDetails).subscribe(response => {
    try {
      if (response.status === 'true') {
      this.countryList = response.data;
      this.mobilePhCode = response.data;
      }
    } catch (error) {
      this.logger.error(error);
    }
  }); } catch (error) {
    this.logger.error(error);
  }
}
// This is the function used to get the mobile code as per country selected
getCountry(event) {
  const mobileCountryCode = event.target.value;
  const splitted = mobileCountryCode.split(': ')[1];
  const getPhoneCode = splitted;
  this.mobilePhCode.forEach(value => {
    if (value.nicename === getPhoneCode) {
      this.auditorDetailsUpdate.patchValue({
        phone_code: value.phonecode
      });
    }
  });
    }
    // This is the function used to get the country code for vat id
    getValueCountry(event) {
      const mobileCountryCode = event.target.value;
      const splitted = mobileCountryCode.split(': ')[1];
      const getPhoneCode = splitted;
      this.mobilePhCode.forEach(value => {
        if (value.nicename === getPhoneCode) {
          this.clientProfileUpdate.patchValue({
            phone_code: value.phonecode
          });
        }
      });
    }
// This is the function used to get auditor details patch input tag show editing option
// @header:token
// @input: auditor_id:''
// @output: {status:'', message:'', data:[]}
getAuditorList(getAuditorid) {
  try {
  let Params = new HttpParams();
  Params = Params.append('auditor_id', getAuditorid);
  this.dataService.getDataPageNo(AppConfig.settings.SUPERADMINAPI.AUDIOREDITOR, Params,'').subscribe(response => {
   try {
    if (response.status === 'true') {
      this.notification.isUILoader(false, this.loginloader, this.commonLoader);
      this.getAuditorDetails = response.data[0];
        // Patch auditor details
      this.auditorDetailsUpdate.patchValue({
          title_name: this.getAuditorDetails.title_name,
          first_name: this.getAuditorDetails.first_name,
          last_name: this.getAuditorDetails.last_name,
          country_name: this.getAuditorDetails.country_name,
          email: this.getAuditorDetails.email,
          mobile_number: this.getAuditorDetails.mobile_number,
          postal_code: this.getAuditorDetails.postal_code,
          company_name: this.getAuditorDetails.company_name,
          house_no: this.getAuditorDetails.house_no,
          street: this.getAuditorDetails.street,
          phone_code: this.getAuditorDetails.phone_code,
          city: this.getAuditorDetails.city,
          vat_id: this.getAuditorDetails.vat_id,
          vat_code: this.getAuditorDetails.vat_code
        });
    } else {
      this.notification.isUILoader(false, this.loginloader, this.commonLoader);
    }
    } catch (error) {
      this.logger.error(error);
    }
  });  } catch (error) {
    this.logger.error(error);
  }
}
}
