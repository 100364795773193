import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { AppConfig } from 'src/app/app.config';
import { DataService } from 'src/app/data.service';
import { NgiNotificationService } from 'ngi-notification';
import { TranslateService } from  '@ngx-translate/core';
import { ShareLoginDetailsService } from 'src/app/_helpers/login-user-details/share-login-details.service';
import { NgiLoggerService } from 'ngi-logger';
@Component({
  selector: 'app-topnavbar',
  templateUrl: './topnavbar.component.html',
  styleUrls: ['./topnavbar.component.scss']
})
export class TopnavbarComponent implements OnInit {
  @Output() langChoosedEvent = new EventEmitter<string>();
  @Input() choosedLang : String;
  wpAdmin = false;
  auditorAdmin = false;
  clientAdmin = false;
  messageBar = false;
  userName: any;
  userType;
  dashboardTitle;
  gettitleName: any;
  getFirstName: any;
  getLastName: any;
  getUserName: any;
  mobileNumber;
  email;
  companyName: any;
  countryName: any;
  getTopInfo: any;
  topDetail: any;
  notificationCount;
  notificationData: any;
  getnotifcationData: any = [];
  getnotifcalue: any;
  getMultipleId: any;
  getProfileData: any;
  profileRouter = true;
  superAdminPassword = false;
  getLoginUserDetails;

  languageCode:any;
  currentLanguage: any;
  app_choosed_lang: any;
  browser_currentLanguage:any
  activatedRoute: any;
  current_lang: any;
  public languages: any;
  translation_lang:any = [];
  constructor(public translation:  TranslateService,private router: Router, private notification: NgiNotificationService, public dataService: DataService,  private ShareLogin: ShareLoginDetailsService,private logger:NgiLoggerService) {
    
    // this.browser_currentLanguage  =  translation.getBrowserLang();
    // console.log("choosed lang:",this.browser_currentLanguage);
    // translation.setDefaultLang( this.browser_currentLanguage );
    // translation.use( this.browser_currentLanguage );
    // this.app_choosed_lang = this.browser_currentLanguage;
    // this.languageCode =  this.browser_currentLanguage;
    
    // this.languageCode =localStorage.getItem('TRANSLATIONLANGINFO');
    // translation.use(localStorage.getItem('TRANSLATIONLANGINFO'));
    this.get_translation_languages();
   }
   get_translation_languages()
   {
     this.dataService.getData(AppConfig.settings.projects.translationLanguageDetails,'').subscribe(response => {
       try {
         if (response.status === 'true') {           
           this.languages = response.data;
           const found = this.languages.some(el => el.languageCode === localStorage.getItem('TRANSLATIONLANGINFO'));
            if (!found) {             
              this.browser_currentLanguage  =  this.translation.getBrowserLang();
              this.translation.setDefaultLang( this.browser_currentLanguage );
              localStorage.setItem('TRANSLATIONLANGINFO', this.browser_currentLanguage);
              this.languageCode =this.browser_currentLanguage;
              this.translation.use(this.browser_currentLanguage);
            }
            else{
              this.languageCode =localStorage.getItem('TRANSLATIONLANGINFO');
              this.translation.use(localStorage.getItem('TRANSLATIONLANGINFO'));
            }
         } else {
           this.notification.isNotification(true, this.translation.instant('Failure'), this.translation.instant(response.message), 'times-circle');
         }
       } catch (error) {
         this.logger.error(error);
       }
     });
   }
  ngOnInit() {
    this.languageCode =localStorage.getItem('TRANSLATIONLANGINFO');
    this.translation.use(localStorage.getItem('TRANSLATIONLANGINFO'));
       // this.translation.use(localStorage.getItem('TRANSLATIONLANGINFO'));
    // console.log("choosed lang from top navbar to datatable:====================================",this.choosedLang);
    // this.languageCode =this.choosedLang;
    // this.translation.use(this.languageCode);

    // if (this.languageCode === undefined){
    // this.browser_currentLanguage  =  this.translation.getBrowserLang();
    // console.log("choosed lang:",this.browser_currentLanguage);
    // this.translation.setDefaultLang( this.browser_currentLanguage );
    // this.translation.use( this.browser_currentLanguage );
    // this.app_choosed_lang = this.browser_currentLanguage;
    // this.languageCode =  this.browser_currentLanguage;
    // }
    
      this.userType=AppConfig.constants.user_type;
    // This function use to user type best open menus
    if (this.userType === AppConfig.constants.USERTYPELIST.ONE) {
      this.dashboardTitle = AppConfig.constants.DASHBOARDHEADER.SUPERADMIN;
      this.wpAdmin = true;
      this.getFirstName = 'super';
      this.getLastName = 'admin';
      this.profileRouter = false;
      this.superAdminPassword = true;
    } else if (this.userType === AppConfig.constants.USERTYPELIST.TWO) {
      this.dashboardTitle = AppConfig.constants.DASHBOARDHEADER.AUDITORTITLE;
      this.auditorAdmin = true;
      this.userName = this.getUserName;
      this.messageBar = true;
      this.profileRouter = true;
    } else if (this.userType === AppConfig.constants.USERTYPELIST.THREE) {
      this.dashboardTitle = AppConfig.constants.DASHBOARDHEADER.CLIENTTITLE;
      this.clientAdmin = true;
      this.userName = this.getUserName;
      this.profileRouter = true;
    } 
   
    this.getProfileDetails();
    this.getNotificationList();
    this.langChoosedEvent.emit(this.app_choosed_lang)
  }

 

  languageChange($event) {  
    this.currentLanguage = $event;  
    this.translation.use(this.currentLanguage);
    this.app_choosed_lang = this.currentLanguage;
    this.langChoosedEvent.emit(this.app_choosed_lang)
    localStorage.setItem('TRANSLATIONLANGINFO', this.currentLanguage);
    this.languageCode =this.currentLanguage;
    
  } 
  /* This function use get user profile details
  @header: token
  @URL: service API,
  @output: { status:"", message:"", data:[] }
  */
  getProfileDetails() {
    this.dataService.getData(AppConfig.settings.PROFILESERVICES.GETPROFILES,'').subscribe(response => {
      try {
        if (response.status === 'true') {
            this.getProfileData = response.data[0];
            AppConfig.loginUserDetails = response.data[0];
            this.ShareLogin.setUserDetails(response.data[0]);
            this.getFirstName = this.getProfileData.first_name;
            this.getLastName = this.getProfileData.last_name;
        } else {
        }
      } catch (error) {
        this.logger.error(error);
      }
    });
  }
  /* This function use logout your profile
  @header: token
  @URL: service API,
  @output: { status:"", message:"" }
  */
  getLogout() {
    try {
    this.dataService.getData(AppConfig.settings.projects.logout,'').subscribe(response => {
      try {
        if (response.status === 'true') {
      
          localStorage.clear();
          location.reload();
          this.notification.isNotification(true, this.translation.instant('Success'), this.translation.instant(response.message), 'check-square');
          this.router.navigate(['login']);
        } else {
          this.notification.isNotification(true, this.translation.instant('Failure'), this.translation.instant(response.message), 'times-circle');
        }
      } catch (error) {
        this.logger.error(error);
      }
    }); } catch (error) {
      this.logger.error(error);
    }
  }
/* This function use remove to notification message
  @header: token
  @URL: service API,
  @output: { status:"", message:"" }
  */
  getNotificationList() {
    try {
    this.dataService.getData(AppConfig.settings.AUDITORAPI.AUDITORNOTIFICATION,'').subscribe(response => {
      try {
        if (response.status === 'true') {
          this.notificationCount = response.count;
          this.notificationData = response.data;
          localStorage.setItem('NOTIFICATIONVALUE',JSON.stringify(this.notificationData));
          
        } else if (response.status === 'false') {
          localStorage.setItem('NOTIFICATIONVALUE',JSON.stringify(''));
         
        }
      } catch (error) {
        this.logger.error(error);
      }
    }); } catch (error) {
      this.logger.error(error);
    }
  }
/* This function use remove single notification message
  @header: token
  @URL: service API,
  @output: { status:"", message:""}
  */
  getSinglenotification(getNotificationId) {
    try {
    this.getnotifcalue = {
      notification_id: [getNotificationId]
    };
    this.dataService.postData(AppConfig.settings.CLIENTSERVICEAPI.REMOVENOTIFICATION, this.getnotifcalue,'').subscribe(response => {
      try {
        if (response.status === 'true') {
          this.router.navigate(['/dashboard/file-management']);
        } else {
        }
      } catch (error) {
        this.logger.error(error);
      }
    }); } catch (error) {
      this.logger.error(error);
    }
  }
/* This function use removed all notification message
  @header: token
  @URL: service API,
  @output: { status:"", message:""}
  */
  getMultinotification() {
      try {
    this.notificationData.forEach(value => {
      this.getnotifcationData.push(value.id);
      });
    this.getMultipleId = {
      notification_id: this.getnotifcationData
    };
    this.dataService.postData(AppConfig.settings.CLIENTSERVICEAPI.REMOVENOTIFICATION, this.getMultipleId,'').subscribe(response => {
      try {
        if (response.status === 'true') {
          this.router.navigate(['/dashboard/file-management']);
        } else {
        }
      } catch (error) {
        this.logger.error(error);
      }
    }); } catch (error) {
      this.logger.error(error);
    }
  }
}
