import { NgModule } from '@angular/core';
import { NgiDatatableComponent } from './ngi-datatable.component';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { NgiObjectHeadersPipe } from './ngi-object-header.pipe';
import { NgiObjectReportdataPipe } from './ngi-object-items.pipe';
import { DataTablesModule } from 'angular-datatables';
import { TranslateModule } from '@ngx-translate/core';
@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    DataTablesModule,
    TranslateModule
  ],
  declarations: [NgiDatatableComponent, NgiObjectHeadersPipe, NgiObjectReportdataPipe],
  exports: [NgiDatatableComponent,TranslateModule]
})
export class NgiDatatableModule { }
