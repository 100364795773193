import { Component, OnInit, Input, EventEmitter, Output, AfterViewInit } from '@angular/core';
import { Router } from '@angular/router';
import { AppConfig } from 'src/app/app.config';
import { DataService } from 'src/app/data.service';
import { NgiNotificationService } from 'ngi-notification';

import { ShareLoginDetailsService } from 'src/app/_helpers/login-user-details/share-login-details.service';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  constructor(public dataService: DataService, private shareLogin: ShareLoginDetailsService) { }
  ngOnInit(): void {
    // this. getProfileDetails();
  }

}
