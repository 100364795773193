import { Component, OnInit, Input, Output, EventEmitter, Injector, AfterViewInit } from '@angular/core';
import { FormGroup, FormBuilder, FormControl, FormArray, Validators} from '@angular/forms';
import { Routes, RouterModule, Router } from '@angular/router';
import { AppConfig } from 'src/app/app.config';
import { DataService } from 'src/app/data.service';
import { NgiNotificationService } from 'ngi-notification';
import { SharedService } from 'src/app/shared.service';
import { HttpParams } from '@angular/common/http';
import { NgiLoggerService } from 'ngi-logger';
import { TranslateService } from  '@ngx-translate/core';
@Component({
  selector: 'app-alert-vendor-supplier',
  templateUrl: './alert-vendor-supplier.component.html',
  styleUrls: ['./alert-vendor-supplier.component.scss']
})
export class AlertVendorSupplierComponent implements OnInit {
  @Output() addMailData: EventEmitter<any> = new EventEmitter();
  @Output('CloseWidget') CloseWidget: EventEmitter<any> = new EventEmitter();
  injectData: any;
  getAlertMessage1;
  getAlertMessage2;
  commonLoader = AppConfig.constants.COLORCODE.commonloadcolor; // Loader Icon color
  loginloader: string = this.randomGenerator();  // loader variable declare
  constructor(public translation:  TranslateService,private formBuilder: FormBuilder, private sharedService: SharedService, private fb: FormBuilder, private router: Router, private notification: NgiNotificationService, public dataService: DataService, private injector: Injector, private logger: NgiLoggerService) {
    this.injectData = this.injector.get('injectData');
 }
 /* Ramdom gerator function using loading time, alignment center */
 randomGenerator(): string {
  return Math.floor(Math.random() * 100) + 2 + '' + new Date().getTime() + Math.floor(Math.random() * 100) + 2 + (Math.random().toString(36).replace(/[^a-zA-Z]+/g, '').substr(0, 5));
}
  ngOnInit() {
    this.getAlertMessage1 = this.injectData.getData.getAlertMessage1;
    this.getAlertMessage2 = this.injectData.getData.getAlertMessage2;
  }
  cancelBtn() {
    this.CloseWidget.emit('CloseWidget');
}
onTiggerArgeed() {
  try {
  if (this.injectData.action === 'argeeDocument') {
    this.sharedService.announceMission('argeeDocumentDetails'); // This is the message send to client datatable details respone message
    this.cancelBtn();
  } else if (this.injectData.action === 'disargeeDocument') {
    this.sharedService.announceMission('disargeeDocumentDetails'); // This is the message send to client datatable details respone message
    this.cancelBtn();
  } else if (this.injectData.action === 'openCancelPostMsg') {
    this.cancelManualSelectionData();
  } else if (this.injectData.action === 'closeManualSelection')  {
    this.cancelManualSelectionData()
  } else if (this.injectData.action === 'openSavePostMsg'){
    this.saveSampledDatas(this.injectData.postData)
  }else if (this.injectData.action === 'manualConfirmPostMsg' || this.injectData.action === 'openConfirmPostMsg'){
    this.confirmManualSelection(this.injectData.postData)
  }else if( this.injectData.action === 'closeMusTestingTableForInterpolation')
  {
    this.closeMusTestingTableForInterpolation()
  }
  // 
  else  if (this.injectData.action === 'auditorFileUpload'){
    this.confirmFileProcessing()
  }
} catch (error) {
  this.logger.error(error);
}
}


closeMusTestingTableForInterpolation()
{
  this.cancelBtn();
  this.router.navigate(['/dashboard/MUS-processed-files']);
}

cancelManualSelectionData(){
  this.cancelBtn();
  this.router.navigate(['/dashboard/MUS-process']);
}

confirmFileProcessing(){
  this.cancelBtn();
  this.sharedService.announceMission("confirmAuditorFiles");
}
/*
  This is function using Confirm MUS Result details
  @header: token, URL: services API,
  @input: sampling_id:"",file_type:"",accepted:""
  @output: {status:"", message:"", data:[]}
  */
 postConfirmData(getData) {
  try {
  this.notification.isUILoader(true, this.loginloader, this.commonLoader);
  this.dataService.getURL(AppConfig.settings.BALCALCULATION.ACCEPTRESULT+'/'+getData.sampling_id+'/'+getData.file_type+'/'+getData.accepted).subscribe(respone => {
    try {
      if (respone.status === 'true') {
        this.notification.isUILoader(false, this.loginloader, this.commonLoader);
        this.router.navigate(['/dashboard/MUS-process']);
        this.notification.isNotification(true, this.translation.instant('Success'), this.translation.instant(respone.message), 'check-square');
        this.cancelBtn();
      } else {
        this.notification.isUILoader(false, this.loginloader, this.commonLoader);
        this.router.navigate(['/dashboard/MUS-process']);
        this.notification.isNotification(true, this.translation.instant('Failure'), this.translation.instant(respone.message), 'times-circle');
        this.cancelBtn();
      }
    } catch (error) {
      this.logger.error(error);
    }
  }); } catch (error) {
    this.logger.error(error);
  }
}

confirmManualSelection(getData?:any){
  // postWithOutTocken
  try {
    this.notification.isUILoader(true, this.loginloader, this.commonLoader);
    this.dataService.postWithOutTocken(AppConfig.settings.projects.ADDMANUALPROCESS, getData).subscribe(res=>{
      if (res.status === 'true') {
        this.notification.isUILoader(false, this.loginloader, this.commonLoader);
        this.notification.isNotification(true, this.translation.instant('Success'), this.translation.instant(res.message), 'check-square');
        this.router.navigate(['/dashboard/MUS-process']);
        this.cancelBtn();
      } else {
        this.notification.isUILoader(false, this.loginloader, this.commonLoader);
        this.router.navigate(['/dashboard/MUS-process']);
        this.notification.isNotification(true, this.translation.instant('Failure'), this.translation.instant(res.message), 'times-circle');
        this.cancelBtn();
      }
    })
  } catch (error) {
      this.logger.error(error);
    }
}
saveSampledDatas(getData?:any){
  // postWithOutTocken
  try {
    this.notification.isUILoader(true, this.loginloader, this.commonLoader);
    this.dataService.postWithOutTocken(AppConfig.settings.projects.SAVESAMPLEDDATAS, getData).subscribe(res=>{
      if (res.status === 'true') {
        this.notification.isUILoader(false, this.loginloader, this.commonLoader);
        this.router.navigate(['/dashboard/MUS-process']);
        this.notification.isNotification(true, this.translation.instant('Success'), this.translation.instant(res.message), 'check-square');
        this.cancelBtn();
      } else {
        this.notification.isUILoader(false, this.loginloader, this.commonLoader);
        this.router.navigate(['/dashboard/MUS-process']);
        this.notification.isNotification(true, this.translation.instant('Failure'), this.translation.instant(res.message), 'times-circle');
        this.cancelBtn();
      }
    })
  } catch (error) {
      this.logger.error(error);
    }
}




}
