import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ShareLoginDetailsService {
loginUserDetails: any;
  constructor() { }
setUserDetails(data) {
this.loginUserDetails = data;
}

getUserDetails() {
  return this.loginUserDetails;
}
}
