import { Component, OnInit, Input, Output, EventEmitter, Injector, AfterViewInit } from '@angular/core';
import { FormGroup, FormBuilder, FormControl, FormArray, Validators} from '@angular/forms';
import { Routes, RouterModule, Router } from '@angular/router';
import { AppConfig } from 'src/app/app.config';
import { DataService } from 'src/app/data.service';
import { NgiNotificationService } from 'ngi-notification';
import { SharedService } from 'src/app/shared.service';
import { HttpParams } from '@angular/common/http';

@Component({
  selector: 'app-vendor-supplier-comments',
  templateUrl: './vendor-supplier-comments.component.html',
  styleUrls: ['./vendor-supplier-comments.component.scss']
})
export class VendorSupplierCommentsComponent implements OnInit {
  @Output('CloseWidget') CloseWidget: EventEmitter<any> = new EventEmitter();
  commonLoader = AppConfig.constants.COLORCODE.commonloadcolor; // common loader color code
  loginloader: string = this.randomGenerator();  // Loader variable declare

  injectDataList: any;
  getAlertMessage;
  constructor(private formBuilder: FormBuilder, private sharedService: SharedService, private fb: FormBuilder, private router: Router, private notification: NgiNotificationService, public dataService: DataService, private injector: Injector) {
    this.injectDataList = this.injector.get('injectData') || {};
   }

  ngOnInit() {
    this.getMessageData();
  }
 // Loader randomgenerator script
 randomGenerator(): string {
  return Math.floor(Math.random() * 100) + 2 + '' + new Date().getTime() + Math.floor(Math.random() * 100) + 2 + (Math.random().toString(36).replace(/[^a-zA-Z]+/g, '').substr(0, 5));
}
getMessageData() {
  if(this.injectDataList.action === 'addEmail'){
    if (this.injectDataList.data.commentsmsg === '') {
    this.getAlertMessage = 'No Comments';
    } else {
      this.getAlertMessage = this.injectDataList.data.commentsmsg;
    }
  }
  else if (this.injectDataList.action === 'viewAuditorReference')
  {
    if (this.injectDataList.data.auditorCommentsmsg === '') {
      this.getAlertMessage = 'No Comments';
      } else {
        this.getAlertMessage = this.injectDataList.data.auditorCommentsmsg;
      }
  }
}
cancelBtn() {
  this.CloseWidget.emit('CloseWidget');
}
}
