import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DataTablesModule } from 'angular-datatables';
import { LayoutModule } from '../layout/layout.module';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {NgiNotificationModule} from 'ngi-notification';
import { NgiDatatableModule } from 'projects/ngi-datatable/src/public_api';
import { NgiWidgetsModule } from 'projects/ngi-widgets/src/public_api';
import { DynamicComponentLoaderModule } from '../factories/dynamic-component-loader.module';
import { DynamicComponentManifest } from '../factories/dynamic-component-manifest';
import { RecaptchaModule } from 'ng-recaptcha'
import{NgiLoggerModule } from 'ngi-logger';
import { TranslateModule } from '@ngx-translate/core';

const manifests: DynamicComponentManifest[] = [
  {
    componentId: 'widgets',
    path: 'dynamic-message', // some globally-unique identifier, used internally by the router
    loadChildren: '../dynamic-layouts/widgets-layout/widgets-layout.module#WidgetsLayoutModule'
  }
];

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    DataTablesModule,
    LayoutModule,
    FormsModule,
    ReactiveFormsModule,
    NgiNotificationModule,
    NgiDatatableModule,
    NgiWidgetsModule,
    RecaptchaModule,
    NgiLoggerModule.forRoot(),
    DynamicComponentLoaderModule.forRoot(manifests),
    TranslateModule
  ],
  exports: [
    CommonModule,
    DataTablesModule,
    LayoutModule,
    FormsModule,
    ReactiveFormsModule,
    NgiNotificationModule,
    NgiDatatableModule,
    NgiWidgetsModule,
    RecaptchaModule,
    TranslateModule
  ]
})
export class SharedModule { }
