import { Component, OnInit, Input, Output, EventEmitter, Injector, AfterViewInit, ViewEncapsulation } from '@angular/core';
import { FormGroup, FormBuilder, FormControl, FormArray, Validators } from '@angular/forms';
import { Routes, RouterModule, Router } from '@angular/router';
import { AppConfig } from 'src/app/app.config';
import { DataService } from 'src/app/data.service';
import { NgiNotificationService } from 'ngi-notification';
import { SharedService } from 'src/app/shared.service';
import { HttpParams } from '@angular/common/http';
import { NgiLoggerService } from 'ngi-logger';
import { Observable, Subject } from 'rxjs';
import {HttpClient, HttpHeaders, HttpResponse} from '@angular/common/http';
import { TranslateService } from  '@ngx-translate/core';
@Component({
  selector: 'app-confirmation-message',
  templateUrl: './confirmation-message.component.html',
  styleUrls: ['./confirmation-message.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ConfirmationMessageComponent implements OnInit {
  @Output() addMailData: EventEmitter<any> = new EventEmitter();
  @Output('CloseWidget') CloseWidget: EventEmitter<any> = new EventEmitter();
  vendorSupplierEmail: any
  commonView: any;
  injectData: any;
  getAlertMessage: string;
  getAlertMessage1;
  getAlertMessage2;
  getAlertMessage3
  viewEmailHistory: any;
  getHistoryDetail: any;
  getTitle: any;
  ChangeStatusDetails: any;
  auditorDigitalSignForm:any;
  viewReference: any;
  messageColor:any;
  statusView: any;
  logo: any;
  commonLoader = AppConfig.constants.COLORCODE.commonloadcolor; // Loader Icon color
  loginloader: string = this.randomGenerator();  // loader variable declare
  statusOption: any;
  contentNew: string;
  UpdateMailData = new FormGroup({
    vendor_or_supplier_email: new FormControl('', [Validators.required, Validators.pattern(AppConfig.settings.formvalidkay.EMAILPATTEN)])
  })
  UpdateEmailPreviewForm = new FormGroup({
    email_content: new FormControl(''),
  })
  UpdateStatus = new FormGroup({
    status: new FormControl('', [Validators.required]),
    comments: new FormControl('', [Validators.required, Validators.minLength(AppConfig.settings.formvalidkay.COMMENTMIN), Validators.maxLength(AppConfig.settings.formvalidkay.COMMENTMXA)]),
  })
  uploadAuditorDigitalSign = new FormGroup({
    auditor_first_name: new FormControl('',  [Validators.required, Validators.minLength(AppConfig.settings.formvalidkay.FIRSTNAMEMINIMUM), Validators.maxLength(AppConfig.settings.formvalidkay.FIRSTNAMEMAXIMUM), Validators.pattern(AppConfig.settings.formvalidkay.STRINGPATTAN)]),
    auditor_last_name: new FormControl('',  [Validators.required, Validators.minLength(AppConfig.settings.formvalidkay.LASTNAMEMINIMUM), Validators.maxLength(AppConfig.settings.formvalidkay.LASTNAMEMAXIMUM), Validators.pattern(AppConfig.settings.formvalidkay.CHARACTERSALONGWITHHYPHEN)]),
    auditor_email:new FormControl('', [Validators.required, Validators.pattern(AppConfig.settings.formvalidkay.EMAILPATTEN)]),
    auditor_mobile_number: new FormControl('', [
      Validators.required, Validators.minLength(AppConfig.settings.formvalidkay.MOBILEMINIMUM), Validators.maxLength(AppConfig.settings.formvalidkay.MOBILEMAXIMUM), Validators.pattern(AppConfig.settings.formvalidkay.NUMBERPATTEN)
    ]),
    company_name: new FormControl('', [Validators.required, Validators.minLength(AppConfig.settings.formvalidkay.COMMONMIN), Validators.maxLength(AppConfig.settings.formvalidkay.COMMONMAX)]),
    logo: new FormControl(''),  
    city: new FormControl('', [Validators.required, Validators.minLength(AppConfig.settings.formvalidkay.COMMONMIN), Validators.maxLength(AppConfig.settings.formvalidkay.COMMONMAX)]),
    street: new FormControl('', [Validators.required, Validators.minLength(AppConfig.settings.formvalidkay.COMMONMIN), Validators.maxLength(AppConfig.settings.formvalidkay.COMMONMAX)]),
    house_no: new FormControl('', [Validators.required, Validators.minLength(AppConfig.settings.formvalidkay.HOUSNOMIN), Validators.maxLength(AppConfig.settings.formvalidkay.HOUSNOMAX)]),
    country_name: new FormControl(null, [ Validators.required ] ),
      
  })
  showMessage: any;
  countryList;
  mobileCode;
  previewEmailForm: boolean;
  showpreviewemailForm: boolean;
  email_content: any;
  externalHtml: any;
  sanitizer: any;
  confirm_email_content: any;
  submitForm: boolean;
  constructor(public translation:  TranslateService,private http: HttpClient,private formBuilder: FormBuilder, private sharedService: SharedService, private fb: FormBuilder, private router: Router, private notification: NgiNotificationService, public dataService: DataService, private injector: Injector, private logger: NgiLoggerService) {
    this.statusOption = AppConfig.constants.STATUSOPTIONS;
    this.injectData = this.injector.get('injectData');
    this.UpdateStatus
  }
  /* Ramdom gerator function using loading time, alignment center */
  randomGenerator(): string {
    return Math.floor(Math.random() * 100) + 2 + '' + new Date().getTime() + Math.floor(Math.random() * 100) + 2 + (Math.random().toString(36).replace(/[^a-zA-Z]+/g, '').substr(0, 5));
  }
  ngOnInit() {
    if (this.injectData.action === "sendAuditorMailvendorsupper") {
      this.EditClientMail()
    } else if (this.injectData.action === "viewMailHistory") {
      this.viewMailHistoryDetails()
    } else if (this.injectData.action === "ChangeStatus") {
      this.ChangeStatusDetails = true
      this.commonView = false;
      this.viewEmailHistory = false;
      this.vendorSupplierEmail = false;
      this.viewReference = false;
      this.changeStatus()
    }else if (this.injectData.action === "uploadAuditorDigitalSign") {
      this.submitForm = false;
      this.auditorDigitalSignForm = true;
      this.getAuditorDigitalSignDetails()
    } 
    else if (this.injectData.action === "referenceView") {
      this.ChangeStatusDetails = false
      this.commonView = false;
      this.viewEmailHistory = false;
      this.vendorSupplierEmail = false;
      this.viewReference = true;
      this.getAlertMessage = this.injectData.alertMessage.getAlertMessage;
    } else if (this.injectData.action === "auditorReference") {
      this.ChangeStatusDetails = false
      this.commonView = false;
      this.viewEmailHistory = false;
      this.vendorSupplierEmail = false;
      this.viewReference = true;
      this.getAlertMessage = this.injectData.alertMessage.getAlertMessage;
    } 
    else if (this.injectData.action === "sendClientDoc") {  
      this.ChangeStatusDetails = false
      this.commonView = true;
      this.viewEmailHistory = false;
      this.vendorSupplierEmail = false;
      this.viewReference = false;
      this.getAlertMessage1 = this.injectData.alertMessage.getAlertMessage1;
      this.getAlertMessage2 = this.injectData.alertMessage.getAlertMessage2;
    }else if (this.injectData.action === "previewEmailContent") {  
      this.ChangeStatusDetails = false
      this.commonView = false;
      this.previewEmailForm = true;
      this.viewEmailHistory = false;
      this.vendorSupplierEmail = false;
      this.viewReference = false;
      this.getAlertMessage1 = this.injectData.alertMessage.getAlertMessage1;
      this.getAlertMessage2 = this.injectData.alertMessage.getAlertMessage2;
      this.getAlertMessage3 = this.injectData.alertMessage.getAlertMessage3;
    }
     else {
      this.commonView = true;
      this.viewEmailHistory = false;
      this.vendorSupplierEmail = false;
      this.viewReference = false;
      this.getAlertMessage1 = this.injectData.alertMessage.getAlertMessage1;
      this.getAlertMessage2 = this.injectData.alertMessage.getAlertMessage2;
    }
    this.getCountryList();
  }

  getCountry(event:any){

  }
  getCountryList() {
    try {
    this.dataService.getCountry(AppConfig.settings.projects.countryDetails).subscribe(response => {
      try {
        if (response.status === 'true') {
        this.countryList = response.data;
        this.mobileCode = response.data;
      }
      } catch (error) {
        this.logger.error(error);
      }
    }); } catch (error) {
      this.logger.error(error);
    }
  }

  getAuditorDigitalSignDetails()
  {
    this.dataService.getData(AppConfig.settings.PROFILESERVICES.GETAUDITORDIGITALSIGN,'').subscribe(response => {
      try {
        if (response.status === 'true') {
          this.uploadAuditorDigitalSign.patchValue({
            auditor_first_name: response.data.first_name,
            auditor_last_name:response.data.last_name,
            auditor_mobile_number:response.data.mobile_number,
            company_name:response.data.company_name,
            street:response.data.street,
            house_no:response.data.house_no,
            city:response.data.city,
            country_name:response.data.country,
            auditor_email:response.data.email,
            logo:response.data.logo_url

          })
          this.submitForm = true
          this.notification.isNotification(true, this.translation.instant('Success'), this.translation.instant(response.message), 'check-square');
        } else {
          this.notification.isNotification(true, this.translation.instant('Failure'), this.translation.instant(response.message), 'times-circle');
        }
      } catch (error) {
        this.logger.error(error);
      }
    });
  }
  // This is function used to change status
  changeStatus(){
    if (this.injectData.getData.file_type === 'Debtor') {
      this.statusView = 'statusVendor';
      if(this.injectData.getData.vendor_confirmation === "--"){
        this.UpdateStatus.patchValue({
          status: "pending"
        })
      }else{
        this.UpdateStatus.patchValue({
          status: this.injectData.getData.vendor_confirmation
        })
      }
     
    } else if (this.injectData.getData.file_type === 'Creditor') {
      this.statusView = 'statusSupplier';
      if(this.injectData.getData.supplier_confirmation === "--"){
        this.UpdateStatus.patchValue({
          status: "pending"
        })
      } else {
        this.UpdateStatus.patchValue({
          status: this.injectData.getData.supplier_confirmation
        })
      }      
    } else if (this.injectData.getData.file_type === 'other') {
      this.statusView = 'statusSupplier';
      if(this.injectData.getData.other_confirmation === "--"){
        this.UpdateStatus.patchValue({
          status: "pending"
        })
      } else {
        this.UpdateStatus.patchValue({
          status: this.injectData.getData.other_confirmation
        })
      }      
    }
  }
  EditClientMail() {
    this.commonView = false;
    this.vendorSupplierEmail = true;
    this.viewEmailHistory = false;
    if (this.injectData.getData.file_type === 'Debtor') {
      this.UpdateMailData.patchValue({
        vendor_or_supplier_email: this.injectData.getData.debtor_email
      })
    } else if (this.injectData.getData.file_type === 'Creditor') {
      this.UpdateMailData.patchValue({
        vendor_or_supplier_email: this.injectData.getData.creditor_email
      })
    }

  }
  viewMailHistoryDetails(getValue?:any) {
    this.commonView = false;
    this.vendorSupplierEmail = false;
    this.viewEmailHistory = true;
    this.getHistoryDetail = this.injectData.alertMessage.data;
    this.getTitle = this.injectData.alertMessage.emailTitle;
  }
  cancelBtn() {
    this.CloseWidget.emit('CloseWidget');
  }
  onTiggerArgeed() {
    if (this.injectData.action === "sendClientDoc") {
      this.sendClientDocument(this.injectData.getData);
    } else if (this.injectData.action === "sendMailToAll") {
      this.sendMailClient(this.injectData.getData);
    }  else {
      this.sendLoginDetails(this.injectData.getData);
    }
  }

  onPreviewEmailTiggerArgeed(){
    
    try{
      if (this.injectData.action === "previewEmailContent") {
        this.previewEmailForm = false;
        this.showpreviewemailForm = true;        
        this.notification.isUILoader(true, this.loginloader, this.commonLoader);
        this.dataService.postData(AppConfig.settings.BALCALCULATION.GETPREVIEWTEMPLATEFORMDETAILS, this.injectData.getData, '').subscribe(response => {
          try {            
            if (response.status === 'true') {
              this.notification.isUILoader(false, this.loginloader, this.commonLoader);
              this.email_content = response.data;
            } else {
              this.notification.isUILoader(false, this.loginloader, this.commonLoader);
              this.notification.isNotification(true, this.translation.instant('Failure'), this.translation.instant(response.message), 'times-circle');
              this.cancelBtn();
            }
          }
          catch (error) {
                  this.logger.error(error);
                  this.notification.isUILoader(false, this.loginloader, this.commonLoader);
                }
        })       
       
      }
    }
    catch (e) {
      this.logger.error(e);
    }
  }
  
  onRuleEditorFullKeyUp(value: string)
  {
    this.confirm_email_content = value;
  }

  
  content : string = "<p>This is my editable data.</p><p>Two way binding is   not getting applied on it.</p>";

  public validateProfile(content){
    this.contentNew = content;
    this.content = this.contentNew;
    if (content === undefined || content === null)
    {
      this.content = this.email_content;
    }
  }
  confirmingEmailAndSendEmail(content)
  {
    this.contentNew = content;
    this.content = this.contentNew;
    if (content === undefined || content === null)
    {
      this.content = this.email_content;
    }
    let postData:any = {
      id : this.injectData.getData.id,
      file_type : this.injectData.getData.file_type,
      html_template : this.content
    }
    this.notification.isUILoader(true, this.loginloader, this.commonLoader);
    this.notification.isUILoader(true, this.loginloader, this.commonLoader);
    this.dataService.postData(AppConfig.settings.BALCALCULATION.SENDLOGINCREDENTIAL, postData, '').subscribe(response => {
      try {
        if (response.status === 'true') { // response successfully
          this.notification.isUILoader(false, this.loginloader, this.commonLoader);
          if (response.data[0].user_type === 'vendor') {
            this.sharedService.announceMission('statusVendor'); // This is the message send to client datatable details response message
          } else if (response.data[0].user_type === 'supplier') {
            this.sharedService.announceMission('statusSupplier'); // This is the message send to client datatable details response message
          }
          this.cancelBtn();
          this.notification.isNotification(true, this.translation.instant('Success'), this.translation.instant(response.message), 'check-square');
        } else { // response failed
          this.notification.isUILoader(false, this.loginloader, this.commonLoader);
          this.notification.isNotification(true, this.translation.instant('Failure'), this.translation.instant(response.message), 'times-circle');
          this.cancelBtn();
        }
      } catch (error) {
        this.logger.error(error);
        this.notification.isUILoader(false, this.loginloader, this.commonLoader);
        this.notification.isUILoader(false, this.loginloader, this.commonLoader);
      }
    });
  }
 
   
  readFile(file: File): Observable<string> {
    const sub = new Subject<string>();
    var reader = new FileReader();
 
    reader.onload = () => {
       const content: any = reader.result;
       sub.next(content);
       sub.complete();
    };
 
    reader.readAsText(file);
    return sub.asObservable();
 }
  // this is function used to send client document
  sendClientDocument(getValue: any) {
    let postValue: any = {
      sampling_id: getValue.id
    }
    try {
      this.notification.isUILoader(true, this.loginloader, this.commonLoader);
      this.dataService.postData(AppConfig.settings.projects.sendDocClient, postValue, '').subscribe(response => {
        if (response.status === 'true') {
          this.notification.isUILoader(false, this.loginloader, this.commonLoader);
          this.sharedService.announceMission(this.statusView);
          this.notification.isNotification(true, this.translation.instant('Success'), this.translation.instant(response.message), 'check-square');
          this.cancelBtn();
        } else {
          this.notification.isUILoader(false, this.loginloader, this.commonLoader);
          this.notification.isNotification(true, this.translation.instant('Failure'), this.translation.instant(response.message), 'times-circle');
          this.cancelBtn();
        }
      })
    } catch (e) {
      this.logger.error(e);
    }
  }
    // this is function used to send client document
    sendMailClient(getValue: any) {
      try {
        let postinput = [];
        postinput.push(
          {"client_unq_id": getValue.client_unq_id,
            "file_type": getValue.file_type,
            "bulk_mail": ''}
          )
        this.notification.isUILoader(true, this.loginloader, this.commonLoader);
        this.dataService.postData(AppConfig.settings.projects.OneClickSendMail, postinput[0], '').subscribe(response => {
          if (response.status === 'true') {
            this.notification.isUILoader(false, this.loginloader, this.commonLoader);
            this.sharedService.announceMission(this.statusView);
            this.notification.isNotification(true, this.translation.instant('Success'), this.translation.instant(response.message), 'check-square');
            this.cancelBtn();
          } else {
            this.notification.isUILoader(false, this.loginloader, this.commonLoader);
            this.notification.isNotification(true, this.translation.instant('Failure'), this.translation.instant(response.message), 'times-circle');
            this.cancelBtn();
          }
        })
      } catch (e) {
        this.logger.error(e);
      }
    }
  // This is function used to change client email id
  onSubmitChangeMailForm() {
    let postValue: any = null;
    if (this.injectData.getData.file_type === "Debtor") {
      this.statusView = 'statusVendor';
      postValue = {
        sampling_id: String(this.injectData.getData.id),
        file_type: this.injectData.getData.file_type,
        debtor_email: this.UpdateMailData.value.vendor_or_supplier_email
      }
    } else if (this.injectData.getData.file_type === "Creditor") {
      this.statusView = 'statusSupplier';
      postValue = {
        sampling_id: String(this.injectData.getData.id),
        file_type: this.injectData.getData.file_type,
        creditor_email: this.UpdateMailData.value.vendor_or_supplier_email
      }
    }
    if (postValue && postValue !== null) {
      try {
        this.notification.isUILoader(true, this.loginloader, this.commonLoader);
        this.dataService.postData(AppConfig.settings.projects.changeMailId, postValue, '').subscribe(response => {
          if (response.status === 'true') {
            this.notification.isUILoader(false, this.loginloader, this.commonLoader);
            this.sharedService.announceMission(this.statusView);
            this.notification.isNotification(true, this.translation.instant('Success'), this.translation.instant(response.message), 'check-square');
            this.cancelBtn();
          } else {
            this.notification.isUILoader(false, this.loginloader, this.commonLoader);
            this.notification.isNotification(true, this.translation.instant('Failure'), this.translation.instant(response.message), 'times-circle');
            this.cancelBtn();
          }
        })
      } catch (e) {
        this.logger.error(e);
      }
    }
  }
  uploadLogo(event)
  {
      const fileList: FileList = event.target.files;
      this.logo = fileList[0];
  }
  onSubmitAuditorSignForm() {
    try {
      this.notification.isUILoader(true, this.loginloader, this.commonLoader);
      const formData: FormData = new FormData();
      if (this.logo === undefined){
        formData.append('mobile_number', this.uploadAuditorDigitalSign.value.auditor_mobile_number);
        formData.append('company_name', this.uploadAuditorDigitalSign.value.company_name);
        formData.append('first_name', this.uploadAuditorDigitalSign.value.auditor_first_name);
        formData.append('last_name', this.uploadAuditorDigitalSign.value.auditor_last_name);
        formData.append('email', this.uploadAuditorDigitalSign.value.auditor_email);
        formData.append('street', this.uploadAuditorDigitalSign.value.street); 
        formData.append('house_no', this.uploadAuditorDigitalSign.value.house_no);  
        formData.append('city', this.uploadAuditorDigitalSign.value.city);
        formData.append('country_name', this.uploadAuditorDigitalSign.value.country_name);

      }
      else{
        formData.append('logo', this.logo,this.logo.name);
        formData.append('mobile_number', this.uploadAuditorDigitalSign.value.auditor_mobile_number);
        formData.append('company_name', this.uploadAuditorDigitalSign.value.company_name);
        formData.append('first_name', this.uploadAuditorDigitalSign.value.auditor_first_name);
        formData.append('last_name', this.uploadAuditorDigitalSign.value.auditor_last_name);
        formData.append('email', this.uploadAuditorDigitalSign.value.auditor_email);  
        formData.append('street', this.uploadAuditorDigitalSign.value.street); 
        formData.append('house_no', this.uploadAuditorDigitalSign.value.house_no);  
        formData.append('city', this.uploadAuditorDigitalSign.value.city);
        formData.append('country_name', this.uploadAuditorDigitalSign.value.country_name);
      }
      this.dataService.postData(AppConfig.settings.PROFILESERVICES.UPLOADAUDITORDIGITALSIGN, formData, '').subscribe(response => {
        try {
          if (response.status === 'true') {
            this.notification.isUILoader(false, this.loginloader, this.commonLoader);
            this.messageColor = AppConfig.constants.COLORCODE.successColor;
            this.showMessage = response.message;
            this.notification.isNotification(true, this.translation.instant('Success'), this.translation.instant(response.message), 'check-square');
            this.cancelBtn();
          } else {
            this.notification.isUILoader(false, this.loginloader, this.commonLoader);
            this.messageColor = AppConfig.constants.COLORCODE.deviationColor;
            this.showMessage = response.message;            
            this.notification.isNotification(true, this.translation.instant('Failure'), this.translation.instant(response.message), 'times-circle');
            this.cancelBtn();
          }
        } catch (error) {
          this.logger.error(error);
        }
      });
    } catch (error) {
      this.logger.error(error);
    }
  }


  onSubmitStatusChangeForm() {
    let postValue:any = {
      user_id : this.injectData.getData.id,
      client_unq_id:this.injectData.getData.client_id,
      file_type:this.injectData.getData.file_type,
      change_status:this.UpdateStatus.value.status,
      referenced_comments:this.UpdateStatus.value.comments
    }
    try {
      this.notification.isUILoader(true, this.loginloader, this.commonLoader);
      this.dataService.postData(AppConfig.settings.projects.AuditorChangeStatus, postValue, '').subscribe(response => {
        if (response.status === 'true') {
          this.notification.isUILoader(false, this.loginloader, this.commonLoader);
          this.sharedService.announceMission(this.statusView);
          this.notification.isNotification(true, this.translation.instant('Success'), this.translation.instant(response.message), 'check-square');
          this.cancelBtn();
        } else {
          this.notification.isUILoader(false, this.loginloader, this.commonLoader);
          this.notification.isNotification(true, this.translation.instant('Failure'), this.translation.instant(response.message), 'times-circle');
          this.cancelBtn();
        }
      })
    } catch (e) {
      this.logger.error(e);
    }
  }
  /*
 This is function used to send the email to vendor are supplier recoarding login credential. when the auditor will trigger send email at second time one uppop will open ask like do you want resend it again.
 @header:token, URL: services API,
 @input:{page_no:"", file_type:""}
 @output:{status:"", message:"", data:[]}
 */
  sendLoginDetails(getData) {
    try {
      this.notification.isUILoader(true, this.loginloader, this.commonLoader);
      this.dataService.postData(AppConfig.settings.BALCALCULATION.SENDLOGINCREDENTIAL, getData, '').subscribe(response => {
        try {
          if (response.status === 'true') { // response successfully
            this.notification.isUILoader(false, this.loginloader, this.commonLoader);
            if (response.data[0].user_type === 'vendor') {
              this.sharedService.announceMission('statusVendor'); // This is the message send to client datatable details response message
            } else if (response.data[0].user_type === 'supplier') {
              this.sharedService.announceMission('statusSupplier'); // This is the message send to client datatable details response message
            }
            this.cancelBtn();
            this.notification.isNotification(true, this.translation.instant('Success'), this.translation.instant(response.message), 'check-square');
          } else { // response failed
            this.notification.isUILoader(false, this.loginloader, this.commonLoader);
            this.notification.isNotification(true, this.translation.instant('Failure'), this.translation.instant(response.message), 'times-circle');
            this.cancelBtn();
          }
        } catch (error) {
          this.logger.error(error);
        }
      });
    } catch (error) {
      this.logger.error(error);
    }
  }
}
