import { Component, OnInit, Input, Output, EventEmitter, Injector, AfterViewInit } from '@angular/core';
import { NgiNotificationService } from 'ngi-notification';
import { SharedService } from 'src/app/shared.service';
import { NgiLoggerService } from 'ngi-logger';
import { AppConfig } from 'src/app/app.config';

@Component({
  selector: 'app-client-email-add',
  templateUrl: './client-email-add.component.html',
  styleUrls: ['./client-email-add.component.scss']
})
export class ClientEmailAddComponent implements OnInit {
  @Output('CloseWidget') CloseWidget: EventEmitter<any> = new EventEmitter();
  commonLoader = AppConfig.constants.COLORCODE.commonloadcolor; // Loader Icon color
  loginloader: string = this.randomGenerator();  // loader variable declare
  injectData: any;

  constructor(private sharedService: SharedService, private notification: NgiNotificationService, private injector: Injector, private logger: NgiLoggerService) {
    this.injectData = this.injector.get('injectData');
   }

 /* Ramdom gerator function using loading time, alignment center */
 randomGenerator(): string {
  return Math.floor(Math.random() * 100) + 2 + '' + new Date().getTime() + Math.floor(Math.random() * 100) + 2 + (Math.random().toString(36).replace(/[^a-zA-Z]+/g, '').substr(0, 5));
}

cancelBtn() {
  this.CloseWidget.emit('CloseWidget');
}
onTiggerEmail(){

}
  ngOnInit() {
  }

}
