import { NgModule } from '@angular/core';
import { NgiWidgetsComponent } from './ngi-widgets.component';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { DynamicComponentLoaderModule, DynamicComponentManifest } from 'src/app/factories/dynamic-component-loader.module';
@NgModule({
  imports: [
    CommonModule,
    DynamicComponentLoaderModule.forChild(NgiWidgetsComponent),
    FormsModule  ],
  declarations: [NgiWidgetsComponent],
  exports: [NgiWidgetsComponent],
  entryComponents: [NgiWidgetsComponent]
})
export class NgiWidgetsModule { }
